import { useEffect } from "react";
import { RiCheckLine, RiErrorWarningLine } from "react-icons/ri";
import {
  Badge,
  Button,
  FormLabel,
  HStack,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useAuthContext } from "@/auth/contexts/authContext";
import { ConnectWithSlack } from "@/common/components/ConnectWithSlack";
import { commonQueryKeys } from "@/common/data/commonQueryKeys";
import api from "@/common/utils/api";
import { compactFormattedDate } from "@/common/utils/datetime";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

export default function SlackOrgIntegration() {
  const { currentOrg } = useAuthContext();

  if (!currentOrg) {
    throw new Error("org missing");
  }

  const queryClient = useQueryClient();

  // todo: handle errors
  const { data: slackConnection, isLoading } = useQuery({
    queryKey: ["slackConnection", currentOrg.id],

    queryFn: () =>
      api
        .get<"/orgs/:orgId/slack/connection">(
          `/orgs/${currentOrg.id}/slack/connection`,
        )
        .then((res) => res.data),
  });

  // todo: handle isError
  const { mutate, isPending: mutationIsLoading } = useMutation({
    mutationFn: () =>
      api.delete<"/orgs/:orgId/slack/connection">(
        `/orgs/${currentOrg.id}/slack/connection`,
      ),

    retry: 1,

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["slackConnection", currentOrg.id],
      });
      // the bootstrap query stores "currentOrg?.hasSlackConnection" which needs to be refetched
      queryClient.invalidateQueries({
        queryKey: commonQueryKeys.bootstrap,
      });
    },
  });

  useEffect(() => {
    segmentAnalytics.page("Org Slack Integration Settings");
  }, []);

  return (
    <>
      <FormLabel pb={3}>Slack integration</FormLabel>
      {isLoading ? (
        <Spinner size="sm" />
      ) : slackConnection ? (
        <VStack align="flex-start" spacing={3}>
          <HStack>
            <Badge colorScheme="green" size="md" variant="subtle">
              <HStack spacing={1}>
                <RiCheckLine size={16} />
                <Text fontSize="sm">Connected</Text>
              </HStack>
            </Badge>
            <Text color="dimmed" fontSize="sm">
              to {slackConnection.team} since{" "}
              {compactFormattedDate(slackConnection.createdAt)}
            </Text>
          </HStack>
          <Button
            isLoading={mutationIsLoading}
            size="sm"
            variant="outline"
            onClick={() => mutate()}
          >
            Disconnect
          </Button>
        </VStack>
      ) : (
        <VStack align="flex-start" spacing={3}>
          <Badge colorScheme="orange" size="md" variant="subtle">
            <HStack spacing={1}>
              <RiErrorWarningLine size={16} />
              <Text fontSize="sm">Not connected</Text>
            </HStack>
          </Badge>
          <Text color="dimmed" fontSize="sm" maxWidth="lg">
            Get notifications whenever a feature&apos;s access/stage changes and
            when end-users submits feature feedback. Subscribe to weekly feature
            view reports.
          </Text>
          <ConnectWithSlack />
        </VStack>
      )}
    </>
  );
}
