import { FC } from "react";
import { Avatar, HStack, Text, TypographyProps } from "@chakra-ui/react";

export type UserDisplayProps = {
  id: string;
  email: string | null;
  name?: string | null;
  avatar?: string | null;
};

export const UserDisplay: FC<{
  user?: UserDisplayProps | null;
  fontSize?: TypographyProps["fontSize"];
  fontWeight?: TypographyProps["fontWeight"];
}> = ({ user, fontSize = "md", fontWeight }) => {
  if (!user) {
    return null;
  }

  const displayText = user.name || user.email || user.id;

  return (
    <HStack as="span">
      <Avatar
        name={displayText || user.id}
        size="2xs"
        src={user.avatar ?? undefined}
      />
      <Text as="span" fontSize={fontSize} fontWeight={fontWeight}>
        {displayText}
      </Text>
    </HStack>
  );
};
