import { Alert, AlertDescription, Flex, Text } from "@chakra-ui/react";

import { Dependee, UIFilter } from "@bucketco/shared/filter";

import { useFilterCheck } from "@/common/data/useFilterCheck";
import pluralize from "@/common/utils/pluralize";
import { FeatureDisplay } from "@/feature/components/FeatureDisplay";

type Props = {
  dependee: Dependee;
  filter: UIFilter;
};

export const ActiveFeaturesAlert = ({ dependee, filter }: Props) => {
  const {
    data: checkDetails,
    isLoading,
    isError,
  } = useFilterCheck(dependee, filter);

  const hasStatelessFilters =
    checkDetails?.features.length && !checkDetails?.stateless;
  const hasInfiniteLoop = checkDetails?.cycle;
  const features = checkDetails?.features;

  if (
    isLoading ||
    isError ||
    !features ||
    (!hasStatelessFilters && !hasInfiniteLoop)
  )
    return null;

  return (
    <Alert alignItems="flex-start" maxW="sm" status="error">
      <AlertDescription lineHeight={1.5}>
        {hasStatelessFilters ? (
          <>
            <Text>
              Some {pluralize("feature", features.length)}{" "}
              {pluralize("use", features.length)} this segment for access
              checking where <strong>first seen</strong>,{" "}
              <strong>last seen</strong> or <strong>feature metrics</strong>{" "}
              filters are not supported:
            </Text>
            <Flex direction="column">
              {features.map((f) => (
                <FeatureDisplay
                  key={f.id}
                  display="inline-flex"
                  feature={f}
                  size="sm"
                  link
                />
              ))}
            </Flex>
          </>
        ) : hasInfiniteLoop ? (
          <Text>
            A dependency cycle with circular references has been detected.
          </Text>
        ) : null}
      </AlertDescription>
    </Alert>
  );
};
