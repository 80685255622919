import {
  RiArrowDownSLine,
  RiChat1Line,
  RiCloseLine,
  RiDoorClosedLine,
  RiHotelLine,
  RiSurveyLine,
  RiUserLine,
} from "react-icons/ri";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  LayoutProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  MenuProps,
  Text,
} from "@chakra-ui/react";

import { DebugEvent } from "@bucketco/shared/eventAPI";

import PointerPressIcon from "@/common/assets/pointer-press.svg?react";
import MenuDescription from "@/common/components/MenuDescription";

export type DebugEventTypeSelectOption = {
  id: DebugEvent["debugEventType"];
  label: string;
  description: string;
  visualization: JSX.Element;
};

const debugEventTypeOptions: DebugEventTypeSelectOption[] = [
  {
    id: "check",
    label: "Check",
    description: "Client checked if user has access to feature",
    visualization: (
      <Box color="dimmed">
        <RiDoorClosedLine height={16} width={16} />
      </Box>
    ),
  },
  {
    id: "evaluate",
    label: "Evaluate",
    description: "Access evaluated in Bucket server-side",
    visualization: (
      <Box color="dimmed">
        <RiSurveyLine height={16} width={16} />
      </Box>
    ),
  },
  {
    id: "user",
    label: "User",
    description: "User identified or updated",
    visualization: (
      <Box color="dimmed">
        <RiUserLine height={16} width={16} />
      </Box>
    ),
  },
  {
    id: "company",
    label: "Company",
    description: "Company identified or updated",
    visualization: (
      <Box color="dimmed">
        <RiHotelLine height={16} width={16} />
      </Box>
    ),
  },
  {
    id: "feedback",
    label: "Feedback",
    description: "Feedback received",
    visualization: (
      <Box color="dimmed">
        <RiChat1Line height={16} width={16} />
      </Box>
    ),
  },
  {
    id: "track",
    label: "Track",
    description: "Adoption/usage event tracked",
    visualization: (
      <Box color="dimmed">
        <PointerPressIcon height={13} width={13} />
      </Box>
    ),
  },
];

export function DebugEventTypeDisplay({
  type,
  showDescription = false,
  labelWidth,
}: {
  type: DebugEvent["debugEventType"];
  showDescription?: boolean;
  labelWidth?: LayoutProps["w"];
}) {
  const { label, description, visualization } = debugEventTypeOptions.find(
    (option) => option.id === type,
  )!;

  return (
    <Flex align="center" gap={2}>
      {visualization}
      <Text w={labelWidth} whiteSpace="nowrap">
        {label}
      </Text>
      {showDescription && (
        <Text variant="dimmed" whiteSpace="nowrap">
          {description}
        </Text>
      )}
    </Flex>
  );
}

type DebugEventTypeSelectProps = Omit<MenuProps, "children"> & {
  canClear?: boolean;
  placeholder?: string;
  value?: DebugEvent["debugEventType"][];
  onChange?: (types: DebugEvent["debugEventType"][]) => void;
};

export function DebugEventTypeSelect({
  value,
  placeholder = "Any event type",
  onChange,
  ...rest
}: DebugEventTypeSelectProps) {
  return (
    <Box>
      <ButtonGroup w="full" isAttached>
        <Menu closeOnSelect={false} {...rest}>
          <MenuButton
            as={Button}
            color={value ? undefined : "dimmed"}
            rightIcon={
              <Box fontSize="xl" mr={-2}>
                <RiArrowDownSLine />
              </Box>
            }
            variant="input"
          >
            <Flex gap={2}>
              {value && value?.length > 0 ? (
                <>
                  {value.map((type) => (
                    <DebugEventTypeDisplay key={type} type={type} />
                  ))}
                </>
              ) : (
                <Text color="dimmed">{placeholder}</Text>
              )}
            </Flex>
          </MenuButton>
          {value && value.length > 0 && (
            <IconButton
              aria-label="Clear selection"
              icon={<RiCloseLine />}
              marginInlineStart={"-1px"}
              variant="outline"
              onClick={() => (onChange ? onChange([]) : undefined)}
            />
          )}
          <MenuList>
            <MenuDescription>Filter events by type</MenuDescription>
            <MenuDivider my={0} />
            <MenuOptionGroup
              type="checkbox"
              value={value}
              onChange={(newValue) => {
                if (newValue.length && onChange)
                  onChange(newValue as DebugEvent["debugEventType"][]);
              }}
            >
              {debugEventTypeOptions.map(({ id }) => (
                <MenuItemOption key={id} value={id}>
                  <DebugEventTypeDisplay
                    labelWidth={16}
                    type={id}
                    showDescription
                  />
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </ButtonGroup>
    </Box>
  );
}
