import { ReactNode } from "react";
import {
  Box,
  Center,
  CenterProps,
  Heading,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";

export type EmptyStateProps = {
  eyebrow?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  action?: ReactNode;
  isLoading?: boolean;
  showContent?: boolean;
  children?: ReactNode;
  icon?: ReactNode;
  maxW?: Pick<CenterProps, "maxW">;
} & Omit<CenterProps, "title">;

const wrapTitle = (title: ReactNode) =>
  typeof title === "string" ? <Heading fontSize="xl">{title}</Heading> : title;

const wrapDescription = (description: ReactNode) =>
  typeof description === "string" ? <Text>{description}</Text> : description;

export default function EmptyState(props: EmptyStateProps) {
  const {
    children,
    eyebrow,
    title,
    description,
    action,
    isLoading = false,
    showContent = true,
    icon,
    maxW = "sm",
    ...centerProps
  } = props;
  return (
    <Center {...centerProps}>
      {isLoading ? (
        <Spinner color="dimmed" size="sm" />
      ) : showContent ? (
        <VStack
          maxW={maxW}
          p={4}
          spacing={4}
          sx={{ textWrap: "balance" }}
          textAlign="center"
        >
          {eyebrow}
          {icon && <Box color="dimmedDisabled">{icon}</Box>}
          {children}
          {wrapTitle(title)}
          {description && (
            <Box color="dimmed" fontSize="sm">
              {wrapDescription(description)}
            </Box>
          )}
          {action}
        </VStack>
      ) : null}
    </Center>
  );
}
