/**
 * Parses a bulk list of IDs separated by spaces or commas into an array of trimmed, non-empty strings.
 * We use it for importing bulk lists of IDs from the user.
 *
 * @param idsString - A string containing IDs separated by spaces or commas.
 * @returns An array of trimmed, non-empty strings representing the IDs.
 */
export function parseIDList(idsString: string): string[] {
  if (!idsString) return [];
  const parsedIds = idsString
    .split(/[\s,]+/)
    .filter((id) => !!id)
    .map((id) => id.trim());

  return parsedIds;
}
