import { FeatureSourceType } from "@bucketco/shared/featureAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import {
  CodeExample,
  CodeExampleAttributeBased,
  CodeExampleProps,
  CodeExampleType,
} from "@/common/components/CodeExample";
import { useFeature } from "@/common/hooks/useFeatureFlags";
import {
  browserAttributeExample,
  browserEventExample,
  nodeAttributeExample,
  nodeEventExample,
  reactAttributeExample,
  reactEventExample,
} from "@/feature/data/codeExamples";
import { codeExamplesByUsecase } from "@/feature/data/codeExamplesByUsecase";

export type CodeExampleSDK = "react" | "node" | "browser";
export type CodeExampleUsecases = "access" | "adoption" | "feedback";

type FeatureCodeExampleProps = Omit<
  CodeExampleProps,
  "examples" | "initialExample"
> & {
  flagKey: string;
  featureSource?: FeatureSourceType;
  usecase: CodeExampleUsecases;
};

const sdkConfig: (Omit<CodeExampleType, "code"> & { key: CodeExampleSDK })[] = [
  {
    key: "react",
    label: "React SDK",
    highlighterLanguage: "jsx",
    docsLink: "https://docs.bucket.co/supported-languages/react-sdk",
  },
  {
    key: "browser",
    label: "Browser SDK",
    highlighterLanguage: "javascript",
    docsLink: "https://docs.bucket.co/supported-languages/browser-sdk",
  },
  {
    key: "node",
    label: "Node.js SDK",
    highlighterLanguage: "javascript",
    docsLink: "https://docs.bucket.co/supported-languages/node.js-sdk",
  },
];

export function FeatureCodeExample({
  flagKey,
  featureSource = "event",
  usecase = "access",
  ...rest
}: FeatureCodeExampleProps) {
  const { currentEnv } = useAuthContext();

  const { isEnabled: isByUsecase } = useFeature("code-examples-by-usecase");

  const publishableKey = currentEnv?.publishableKey ?? "";
  const secretKey = currentEnv?.secretKey ?? "";

  const examples = sdkConfig.map(({ key, ...rest }) => ({
    key,
    code: isByUsecase
      ? getCodeExampleByUsecase({
          flagKey,
          publishableKey,
          secretKey,
          language: key,
          usecase,
        }).trim()
      : getCodeExample(flagKey, featureSource, key).trim(),
    ...rest,
  }));

  return featureSource === "event" ? (
    <CodeExample examples={examples} initialExample="react" {...rest} />
  ) : (
    <CodeExampleAttributeBased />
  );
}

function getCodeExampleByUsecase({
  flagKey,
  publishableKey,
  secretKey,
  language,
  usecase,
}: {
  flagKey: string;
  publishableKey: string;
  secretKey: string;
  language: CodeExampleSDK;
  usecase: CodeExampleUsecases;
}): string {
  return codeExamplesByUsecase(flagKey, publishableKey, secretKey)[language][
    usecase
  ];
}

function getCodeExample(
  flagKey: string,
  featureSource: FeatureSourceType,
  output: CodeExampleSDK,
): string {
  const exampleMap = {
    event: {
      react: reactEventExample,
      browser: browserEventExample,
      node: nodeEventExample,
    },
    attribute: {
      react: reactAttributeExample,
      browser: browserAttributeExample,
      node: nodeAttributeExample,
    },
  };

  return exampleMap[featureSource][output](flagKey);
}
