import { FormProvider, useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";

import { ldImportSchema, ldImportSchemaType } from "@bucketco/shared/importAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import { FormRootError } from "@/common/components/Form/FormRootError";
import FormSubmitLegacy from "@/common/components/Form/FormSubmitLegacy";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import { API_URL } from "@/common/utils/env";

export function Import() {
  const form = useForm<ldImportSchemaType>({
    resolver: zodResolver(ldImportSchema),
    mode: "onChange",
    defaultValues: {
      ldApiToken: "",
      ldProjectId: "default",
      euRegion: false,
    },
  });

  const { currentApp } = useAuthContext();
  const importUrl = `${API_URL}/apps/${currentApp?.id}/import/launchdarkly`;

  return (
    <Flex direction="column" gap={2} maxW="5xl">
      <Heading as="h3" fontSize="md">
        LaunchDarkly Import
      </Heading>
      <Text color="dimmed" fontSize="sm" maxWidth="2xl" mb={4}>
        Enter your details below to download all your LaunchDarkly flags and
        targeting information. Then email us the file at{" "}
        <Link href="mailto:hello@bucket.co">hello@bucket.co</Link> and
        we&apos;ll take care of the rest.
      </Text>

      <Box maxW="xl">
        <form action={importUrl} method="POST">
          <FormProvider {...form}>
            <VStack align={"flex-start"} spacing={4}>
              <ManagedFormControl
                label="LaunchDarkly Project ID"
                name="ldProjectId"
                render={({ field }) => <Input {...field} size="md" />}
              />
              <ManagedFormControl
                label="LaunchDarkly API Token"
                name="ldApiToken"
                render={({ field }) => <Input {...field} size="md" autoFocus />}
              />

              <ManagedFormControl
                label="EU Region"
                name="euRegion"
                render={({ field }) => <Switch {...field} size="md" />}
              />
              <FormRootError />

              <FormSubmitLegacy>Download</FormSubmitLegacy>
            </VStack>
          </FormProvider>
        </form>
      </Box>
    </Flex>
  );
}
