import { Box, BoxProps } from "@chakra-ui/react";

export default function InfiniteAnimation({
  name = "pulse",
  ...props
}: { name?: string } & BoxProps) {
  return (
    <Box
      sx={{
        animationName: name,
        animationDuration: "0.3s",
        animationTimingFunction: "ease-in-out",
        animationDirection: "alternate",
        animationIterationCount: "infinite",
      }}
      {...props}
    />
  );
}
