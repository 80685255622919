import { FormEventHandler, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { Flex } from "@chakra-ui/react";

import { CreateFeatureArgsType } from "@bucketco/shared/featureAPI";
import { generateFeatureKey } from "@bucketco/shared/utils/feature";

import { FormFieldSet } from "@/common/components/Form/FormFieldSet";
import FormInput from "@/common/components/Form/FormInput";
import { useFeatureKeys } from "@/feature/data/useFeatureData";

type Props = {
  id?: string;
  onSubmit?: FormEventHandler<HTMLFormElement>;
};

export const NewFeatureForm = ({ id = "new-feature", onSubmit }: Props) => {
  const { setValue } = useFormContext<CreateFeatureArgsType>();
  const [params] = useSearchParams();
  const existingKeys = useFeatureKeys();

  useMemo(() => {
    console.log("params", params);
    setValue("name", params.get("name") || "", { shouldDirty: true });
    setValue("key", params.get("key") || "", { shouldDirty: true });
  }, [params, setValue]);

  return (
    <form id={id} onSubmit={onSubmit}>
      <Flex direction="column" gap={4}>
        <FormFieldSet>
          <FormInput
            autoComplete="off"
            label="Name"
            name="name"
            placeholder="Export to CSV"
            data-1p-ignore
            onChange={(e) => {
              console.log("onChange", e.currentTarget.value);
              setValue(
                "key",
                generateFeatureKey(
                  e.currentTarget.value,
                  existingKeys.data || [],
                ),
                { shouldDirty: true },
              );
            }}
          />

          <FormInput
            description={
              "The feature key is used to check access, track usage and gather feedback. Cannot be changed."
            }
            descriptionInPortal={false}
            label="Key"
            name="key"
            placeholder="export-to-csv"
          />
        </FormFieldSet>
      </Flex>
    </form>
  );
};
