import { z } from "zod";

export const ldImportSchema = z
  .object({
    ldApiToken: z.string(),
    ldProjectId: z.string(),
    euRegion: z.boolean().default(false),
  })
  .strict();

export type ldImportSchemaType = z.input<typeof ldImportSchema>;
