import { ReactNode } from "react";
import { RiCheckLine, RiCodeSSlashLine } from "react-icons/ri";
import {
  Box,
  Button,
  Card,
  Flex,
  HStack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { useLocalStorage } from "usehooks-ts";

import CardContainer from "@/common/components/CardContainer";
import MotionBox from "@/common/components/MotionBox";
import RadarSpinner from "@/common/components/RadarSpinner";
import { SectionHeading } from "@/common/components/SectionHeading";
import TimestampCell from "@/common/components/TimestampCell";
import { useFeature } from "@/common/hooks/useFeatureFlags";
import {
  CodeExampleUsecases,
  FeatureCodeExample,
} from "@/feature/components/FeatureCodeExample";
import { AnimatedWidget } from "@/feature/components/FeatureWidgets";
import { useFeatureData } from "@/feature/data/useFeatureData";
import { useFeatureRolloutStatusData } from "@/feature/data/useFeatureRolloutStatusData";
import { latestTimestampAcrossAllStatuses } from "@/feature/utils/latestRolloutStatusTimestamp";

const usecaseTitles: Record<CodeExampleUsecases, string> = {
  adoption: "Install adoption tracking",
  access: "Install access checking",
  feedback: "Install feedback button",
} as const;

export const SetupInstructions = ({
  featureId,
  usecase = "access",
}: {
  featureId?: string;
  usecase?: CodeExampleUsecases;
}) => {
  const { data: feature, isLoading: isFeatureLoading } =
    useFeatureData(featureId);

  const iconColor = useColorModeValue("blackAlpha.600", "orange.200");
  const titleColor = useColorModeValue(undefined, "orange.200");
  const hideButtonColorScheme = useColorModeValue("blackAlpha", "gray");

  const [showSetup, setShowSetup] = useLocalStorage(`${featureId}:setup`, true);

  const { isEnabled: isByUsecase } = useFeature("code-examples-by-usecase");

  const title = isByUsecase ? usecaseTitles[usecase] : "Code instructions";

  if (isFeatureLoading || !feature) return null;

  return (
    <AnimatePresence initial={false}>
      {showSetup && (
        <AnimatedWidget key="instructions">
          <CardContainer
            darkModeBorderColor="orange.200"
            gap={1}
            lightModeBgColor="#FBF2E1"
            mb={4}
            position="relative"
          >
            <Flex dir="column" h={8} justify="space-between" px={3} py={0.5}>
              <HStack>
                <Box color={iconColor}>
                  <RiCodeSSlashLine size={16} />
                </Box>
                <SectionHeading color={titleColor}>{title}</SectionHeading>
              </HStack>
              <Button
                colorScheme={hideButtonColorScheme}
                position="absolute"
                right={2}
                top={2}
                variant="ghost"
                onClick={() => setShowSetup(false)}
              >
                Hide
              </Button>
            </Flex>
            <Card direction="row">
              <FeatureCodeExample
                featureSource={feature.source}
                flagKey={feature.key}
                sharingPreface="Implement feature in Bucket"
                trackingEventBase="Feature Instructions"
                usecase={usecase}
              />
            </Card>
            {feature.source === "event" ||
              (!isByUsecase && (
                <Flex
                  align="center"
                  dir="column"
                  fontSize="xs"
                  h={8}
                  justify="space-between"
                  px={3}
                >
                  <RolloutStatusListener featureId={featureId} />
                </Flex>
              ))}
          </CardContainer>
        </AnimatedWidget>
      )}
    </AnimatePresence>
  );
};

function RolloutStatusListener({ featureId }: { featureId?: string }) {
  const { data } = useFeatureRolloutStatusData(featureId);

  const dimmedTextColor = useColorModeValue("blackAlpha.600", "gray.500");
  const radarColor = useColorModeValue("blackAlpha.500", "gray.600");

  if (!data) return null;

  const latest = latestTimestampAcrossAllStatuses(data?.statuses);

  return (
    <AnimatePresence initial={false} mode="wait">
      {latest ? (
        <AnimatedActivityItem key={latest.timestamp}>
          <HStack spacing={1}>
            <RiCheckLine size={16} />
            <Text>
              {latest.type === "check" ? "Check" : "Usage"} registered
            </Text>
            <TimestampCell
              capitalize={false}
              fontSize="xs"
              highlight={false}
              value={latest.timestamp}
              autoUpdate
            />
            <Text>in {latest.env.name}</Text>
          </HStack>
          <RadarSpinner color={radarColor} />
        </AnimatedActivityItem>
      ) : (
        <AnimatedActivityItem key="listening">
          <HStack>
            <RadarSpinner color={radarColor} />
            <Text color={dimmedTextColor}>
              Listening for first check or usage tracking
            </Text>
          </HStack>
        </AnimatedActivityItem>
      )}
    </AnimatePresence>
  );
}

function AnimatedActivityItem({ children }: { children: ReactNode }) {
  return (
    <MotionBox
      alignItems="center"
      animate={{ opacity: 1, translateY: 0 }}
      display="flex"
      exit={{ opacity: 0, translateY: 5 }}
      initial={{ opacity: 0, translateY: -5 }}
      justifyContent="space-between"
      transition={{ duration: "0.2", ease: "easeInOut" }}
      w="full"
    >
      {children}
    </MotionBox>
  );
}
