import { Alert, AlertDescription, Flex, Text } from "@chakra-ui/react";

import { Dependee, UIFilter } from "@bucketco/shared/filter";

import pluralize from "@/common/utils/pluralize";
import { SegmentDisplay } from "@/company/components/SegmentDisplay";
import { DeleteAffectEnvironments } from "@/environment/components/EnvironmentCallouts";
import { FeatureDisplay } from "@/feature/components/FeatureDisplay";
import { useFilterCheck } from "../data/useFilterCheck";

const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

type AffectedDependenciesProps = {
  dependee: Dependee;
  filter?: UIFilter;
  changeType: "delete" | "update";
  showAffectEnvironments?: boolean;
};

const AffectedDependencies = ({
  dependee,
  filter,
  changeType,
  showAffectEnvironments,
}: AffectedDependenciesProps) => {
  const { data } = useFilterCheck(dependee, filter);

  if (!data) {
    return null;
  }

  const { features, segments } = data;
  if (!features.length && !segments.length) {
    if (showAffectEnvironments) {
      return <DeleteAffectEnvironments entity={dependee.type} mt={8} />;
    } else {
      return null;
    }
  }

  if (changeType === "update" && data.cycle) {
    return (
      <Alert alignItems="flex-start" maxW="sm" status="error">
        <AlertDescription>
          <Text lineHeight={1.5}>
            Changes to the filter of this {dependee.type} would create a{" "}
            <strong>cycle</strong>. Modify the filter to remove the cycle.
          </Text>
        </AlertDescription>
      </Alert>
    );
  }

  const hasFeatures = features.length > 0;
  const hasSegments = segments.length > 0;

  const featuresAndSegments = [
    hasSegments ? pluralize("segment", segments.length) : null,
    hasFeatures ? pluralize("feature", segments.length) : null,
  ]
    .filter(Boolean)
    .join("/");

  return (
    <Alert
      alignItems="flex-start"
      maxW="sm"
      status={changeType === "delete" ? "error" : "warning"}
    >
      <AlertDescription lineHeight={1.5}>
        {changeType === "delete" && (
          <Text>
            Deleting this {dependee.type} is <strong>not allowed</strong>{" "}
            because it is a dependency of:{" "}
          </Text>
        )}
        {changeType === "update" && (
          <Text>
            {capitalizeFirstLetter(featuresAndSegments)} that depend on this{" "}
            {dependee.type}:{" "}
          </Text>
        )}
        <Flex direction="column" mt={1}>
          {hasSegments &&
            segments.map((s) => (
              <SegmentDisplay
                key={s.id}
                display="inline-flex"
                segment={s}
                link
              />
            ))}
          {hasFeatures &&
            features.map((f) => (
              <FeatureDisplay
                key={f.id}
                display="inline-flex"
                feature={f}
                size="sm"
                link
              />
            ))}
        </Flex>
      </AlertDescription>
    </Alert>
  );
};

export default AffectedDependencies;
