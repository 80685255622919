import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { HStack, Spacer, Text, Textarea, VStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";

import { CompanyDetail } from "@bucketco/shared/companyAPI";
import { FeatureName } from "@bucketco/shared/featureAPI";
import { Flag } from "@bucketco/shared/flagAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import { ConfirmationDialog } from "@/common/components/ConfirmationDialog";
import { FormRootError } from "@/common/components/Form/FormRootError";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import { SlackNotificationStatus } from "@/common/components/SlackNotificationStatus";
import { useFormMutationSubmitHandler } from "@/common/hooks/useApiForm";
import { companyQueryKeys } from "@/company/data/companyQueryKeys";
import { useFlagVersionsCreateMutation } from "@/flags/data/useFlagVersionCreateMutation";

type Props = {
  company: Pick<CompanyDetail, "id" | "name">;
  feature: Pick<FeatureName, "id" | "name">;
  flag: Pick<
    Flag,
    | "id"
    | "stageId"
    | "currentVersions"
    | "slackChannel"
    | "slackNotificationsEnabled"
  >;
  isOpen?: boolean;
  onClose?: () => void;
};

export const CompanyFeatureToggleDialog = ({
  company,
  feature,
  flag,
  isOpen = true,
  onClose,
}: Props) => {
  const { currentApp, currentEnv } = useAuthContext();
  const currentVersion = flag.currentVersions.find(
    ({ environment }) => environment.id === currentEnv?.id,
  );
  const hasAccess = currentVersion?.companyIds.includes(company.id) || false;
  const queryClient = useQueryClient();

  const placeholder = useCallback(
    (tense: "past" | "future" = "past", lowerCase = false) => {
      let access = !hasAccess
        ? tense === "past"
          ? "Granted"
          : "Grant"
        : tense === "past"
        ? "Revoked"
        : "Revoke";
      access = lowerCase ? access.toLowerCase() : access;
      return `${access} company ${company.name} access to feature ${feature.name} in ${currentEnv?.name}.`;
    },
    [company.name, currentEnv?.name, feature.name, hasAccess],
  );

  const createFlagVersionsMutation = useFlagVersionsCreateMutation(
    feature.id,
    flag.id,
    currentVersion ? [currentVersion.id] : [],
  );

  const form = useForm({
    defaultValues: {
      changeDescription: "",
    },
  });

  const onConfirmClick = useFormMutationSubmitHandler(
    form,
    createFlagVersionsMutation,
    () => {
      queryClient.invalidateQueries({
        queryKey: companyQueryKeys.features(
          currentApp?.id,
          currentEnv?.id,
          company.id,
        ),
      });
      form.resetField("changeDescription");
      onClose?.();
    },
    {
      prepareVariables({ changeDescription }) {
        const companyIds = currentVersion?.companyIds ?? [];
        const targetingMode =
          currentVersion?.targetingMode == "everyone" ? "everyone" : "some";
        return {
          stageId: flag.stageId,
          changeDescription: changeDescription || placeholder("past"),
          versions: [
            {
              environmentId: currentEnv!.id,
              targetingMode,
              segmentIds: currentVersion?.segmentIds ?? [],
              companyIds: !hasAccess
                ? [...companyIds, company.id]
                : companyIds.filter((id) => id !== company.id),
              userIds: currentVersion?.userIds ?? [],
              customRules: currentVersion?.customRules ?? [],
            },
          ],
        };
      },
      successToast: placeholder("past"),
      errorToast: `Failed to ${placeholder("future", true)}`,
    },
  );

  return (
    <FormProvider {...form}>
      <ConfirmationDialog
        _contentProps={{ maxW: "2xl" }}
        description={
          <VStack as="form" justify="flex-start" spacing={4} w="full">
            <ManagedFormControl
              name="changeDescription"
              render={({ field }) => (
                <Textarea placeholder={placeholder("past")} {...field} />
              )}
            />
            <FormRootError />
          </VStack>
        }
        isLoading={createFlagVersionsMutation.isPending}
        isOpen={isOpen}
        title={
          <HStack spacing={4}>
            <Text whiteSpace="nowrap">
              {!hasAccess ? "Grant" : "Revoke"} feature access
            </Text>
            <Spacer />
            {flag.slackNotificationsEnabled && (
              <SlackNotificationStatus
                size="sm"
                slackChannel={flag.slackChannel}
              />
            )}
          </HStack>
        }
        onCancel={() => {
          form.resetField("changeDescription");
          onClose?.();
        }}
        onConfirm={onConfirmClick}
      />
    </FormProvider>
  );
};
