import { useQuery } from "@tanstack/react-query";

import { CompanyName } from "@bucketco/shared/companyAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { companyQueryKeys } from "@/company/data/companyQueryKeys";

function companiesWithNames(
  ids: string[] | undefined,
  resolvedIds: CompanyName[] | undefined,
) {
  return ids?.map((id) => {
    const prevCompany = resolvedIds?.find((c) => c.id === id);
    return {
      name: null,
      ...prevCompany,
      id,
    };
  });
}

export function useCompanyNamesData(ids?: string[]) {
  const { appId, envId } = useCurrentEnv();
  return useQuery({
    queryKey: companyQueryKeys.companyNames(appId, envId, ids),
    queryFn: () =>
      api
        .post<`/apps/:appId/company-names`>(
          `/apps/${appId}/company-names`,
          { ids: ids as [string, ...string[]] },
          {
            params: { envId: envId! },
          },
        )
        .then((res) => companiesWithNames(ids, res.data)),
    enabled: !!appId && !!envId && !!ids?.length,
    placeholderData: (previousData) => companiesWithNames(ids, previousData),
  });
}
