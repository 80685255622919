import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { UserFilter } from "@bucketco/shared/filter";
import { UserPage, UsersQuery } from "@bucketco/shared/userAPI";

import api from "@/common/utils/api";
import { useCurrentEnv } from "../hooks/useCurrentEnv";

import { commonQueryKeys } from "./commonQueryKeys";

type InternalQuery<TData = UserPage> = Partial<
  UseQueryOptions<UserPage, AxiosError<ErrorResponse>, TData>
> & {
  query?: Omit<UsersQuery, "envId">;
};

function useUsersInternal({ query, enabled, ...rest }: InternalQuery) {
  const { appId, envId } = useCurrentEnv();

  return useQuery({
    queryKey: commonQueryKeys.users(appId, envId, query),
    queryFn: () =>
      api
        .get<"/apps/:appId/users">(`/apps/${appId}/users`, {
          params: { ...query, envId: envId! },
        })
        .then((res) => res.data),
    enabled: !!appId && !!envId && enabled,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    placeholderData: keepPreviousData,
    ...rest,
  });
}

export const useUsers = (query?: Omit<UsersQuery, "envId">) =>
  useUsersInternal({ query });

export function useUsersSearch(
  search?: string,
  query: Omit<UsersQuery, "envId" | "filter"> = {},
) {
  const { data, ...rest } = useUsersInternal({
    query: {
      ...query,
      filter: search
        ? ({
            type: "group",
            operator: "or",
            filters: ["id", "name", "email"].map((field) => ({
              type: "userAttribute",
              field,
              operator: "CONTAINS",
              values: [search],
            })),
          } satisfies UserFilter)
        : undefined,
    },
  });

  return { data: data?.data, ...rest };
}

export function useUser(userId?: string) {
  const { appId, envId } = useCurrentEnv();

  return useQuery({
    queryKey: commonQueryKeys.user(appId, envId, userId),
    queryFn: () =>
      api
        .get<"/apps/:appId/users">(`/apps/${appId}/users`, {
          params: {
            filter: {
              type: "userAttribute",
              field: "id",
              operator: "IS",
              values: [userId!],
            } satisfies UserFilter,
            envId: envId!,
          },
        })
        .then((res) => res.data.data[0]),
    enabled: !!appId && !!envId && !!userId,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
}
