import { Period } from "@/common/components/PeriodPicker";
import dayjs from "@/common/utils/dayjs";

export function periodToDates(period: Period) {
  const now = dayjs.utc();
  switch (period) {
    case "past30days":
      return {
        startDate: now.subtract(30, "days").startOf("day"),
        endDate: now.endOf("day"),
      };
    case "past3months":
      return {
        startDate: now.subtract(3, "months").startOf("day"),
        endDate: now.endOf("day"),
      };
    case "past6months":
      return {
        startDate: now.subtract(6, "months").startOf("day"),
        endDate: now.endOf("day"),
      };
  }
}
