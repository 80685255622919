import {
  SystemStyleObject,
  useColorMode,
  useColorModeValue,
  useToken,
} from "@chakra-ui/system";

// Use for variables as they are not substituted
const useTokenColor = (light: string, dark: string) =>
  useToken("colors", useColorModeValue(light, dark));

export const useBucketCodeTheme = (): SystemStyleObject => ({
  // Base editor styles
  ".prism-code-editor": {
    colorScheme: useColorMode().colorMode,
    borderColor: "appBorder",
    borderRadius: "md",
    borderWidth: "1px",
    caretColor: useTokenColor("gray.600", "#aeafad"),
    fontFamily: "code",
    // Core variables
    "--editor__bg": useTokenColor("appBackground", "appBackground"),
    "--widget__border": useTokenColor("gray.200", "gray.700"),
    "--widget__bg": useTokenColor("gray.50", "gray.800"),
    "--widget__color": useTokenColor("gray.600", "gray.300"),
    "--widget__color-active": useTokenColor("gray.900", "white"),
    "--widget__color-options": useTokenColor("gray.500", "gray.400"),
    "--widget__bg-input": useTokenColor("white", "gray.900"),
    "--widget__bg-hover": useTokenColor("gray.100", "gray.700"),
    "--widget__bg-active": useTokenColor("blue.100", "blue.700"),
    "--widget__focus-ring": useTokenColor("blue.500", "blue.400"),
    "--search__bg-find": useTokenColor("yellow.100", "yellow.700"),
    "--widget__bg-error": useTokenColor("red.100", "red.700"),
    "--widget__error-ring": useTokenColor("red.500", "red.400"),
    "--editor__bg-highlight": useTokenColor("gray.50", "gray.800"),
    "--editor__bg-selection-match": useTokenColor("cyan.100", "cyan.800"),
    "--editor__line-number": useTokenColor("gray.400", "gray.500"),
    "--editor__bg-scrollbar": useTokenColor("gray.200", "gray.700"),
    "--editor__bg-fold": useTokenColor("gray.600", "gray.400"),
    "--bg-guide-indent": useTokenColor("gray.200", "gray.700"),
    // Autocomplete icons
    "--pce-ac-icon-class": useTokenColor("orange.500", "orange.400"),
    "--pce-ac-icon-enum": useTokenColor("orange.500", "orange.400"),
    "--pce-ac-icon-function": useTokenColor("purple.500", "purple.400"),
    "--pce-ac-icon-interface": useTokenColor("blue.500", "blue.400"),
    "--pce-ac-icon-variable": useTokenColor("blue.500", "blue.400"),
  },

  // Match highlighting
  ".match-highlight": {
    "--editor__bg-highlight": useTokenColor("gray.50", "gray.800"),
  },

  // Selection
  ".prism-code-editor textarea::selection": {
    background: useTokenColor("cyan.100", "cyan.800"),
    color: "transparent",
  },

  ".prism-code-editor textarea:focus-visible": {
    boxShadow: "none !important",
  },

  // Match styles
  ".pce-matches .match": {
    "--search__bg-find": useTokenColor("yellow.200", "yellow.800"),
  },

  // Active line
  ".active-line": {
    "--editor__line-number": useTokenColor("gray.900", "gray.100"),
  },

  // Guide indents
  ".guide-indents .active": {
    "--bg-guide-indent": useTokenColor("gray.400", "gray.500"),
  },

  // Basic tokens
  ".token.namespace": {
    opacity: 0.7,
    color: useTokenColor("red.600", "red.400"),
  },

  ".token.comment, .token.prolog, .token.doctype, .token.cdata": {
    color: useTokenColor("gray.500", "gray.400"),
  },

  ".token.punctuation, .token.attr-equals": {
    color: useTokenColor("gray.600", "gray.300"),
  },

  ".token.number, .token.symbol, .token.inserted, .token.unit": {
    color: useTokenColor("blue.600", "blue.300"),
  },

  ".token.string, .token.regex, .token.attr-value": {
    color: useTokenColor("green.500", "green.300"),
  },

  ".token.function, .token.class-name": {
    color: useTokenColor("blue.500", "blue.400"),
  },

  ".token.variable, .token.property": {
    color: useTokenColor("red.600", "red.400"),
  },

  // Language specific
  ".language-markup, .language-markdown, .language-md, .language-html, .language-xml, .language-svg, .language-text, .language-css, .language-sass, .language-scss":
    {
      color: useTokenColor("gray.800", "gray.100"),
    },

  // Bracket styles
  ".token.bracket-level-0, .token.bracket-level-3, .token.bracket-level-6, .token.bracket-level-9":
    {
      color: useTokenColor("yellow.500", "yellow.300"),
    },

  ".token.bracket-level-1, .token.bracket-level-4, .token.bracket-level-7, .token.bracket-level-10":
    {
      color: useTokenColor("pink.500", "pink.300"),
    },

  ".token.bracket-level-2, .token.bracket-level-5, .token.bracket-level-8, .token.bracket-level-11":
    {
      color: useTokenColor("blue.500", "blue.300"),
    },

  ".token.bracket-error": {
    color: useTokenColor("red.500", "red.300"),
  },

  // Active elements
  ".active-bracket": {
    boxShadow: `inset 0 0 0 1px ${useTokenColor(
      "gray.300",
      "gray.600",
    )}, inset 0 0 0 9in ${useTokenColor("blue.50", "blue.900")}`,
  },

  ".active-tagname, .word-matches span": {
    background: useTokenColor("gray.100", "gray.700"),
  },

  // Keywords
  ".token.keyword": {
    color: useTokenColor("purple.600", "purple.300"),
  },
});
