import { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  AlertDescription,
  AlertProps,
  Box,
  Text,
  VisuallyHidden,
} from "@chakra-ui/react";
import equal from "fast-deep-equal";

import { SegmentDTOWithTotalCompanies } from "@bucketco/shared/segmentAPI";
import { ColumnState } from "@bucketco/shared/types/columns";
import { SegmentUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import AffectedDependencies from "@/common/components/AffectedDependencies";
import AnimatedSpinner from "@/common/components/AnimatedSpinner";
import HeaderLayout from "@/common/components/HeaderLayout";
import MotionBox from "@/common/components/MotionBox";
import { UpsertPopover } from "@/common/components/UpsertPopover";
import { useFilterCheck } from "@/common/data/useFilterCheck";
import { useDebounce } from "@/common/hooks/useDebounce";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import pluralize from "@/common/utils/pluralize";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { ActiveFeaturesAlert } from "@/company/components/ActiveFeaturesAlert";
import { CompaniesTable } from "@/company/components/CompaniesTable";
import { useCompaniesData } from "@/company/data/useCompaniesData";
import { useSegments } from "@/company/data/useSegments";
import { useSegmentUpsertMutations } from "@/company/data/useSegmentUpsertMutations";
import { useCompaniesTableState } from "@/company/hooks/useCompaniesTableState";
import { useSegmentParam } from "@/company/hooks/useSegmentParam";
import { SegmentNavigator } from "../components/SegmentNavigator";

const ConditionalAlert = ({ children, ...rest }: AlertProps) =>
  children ? (
    <Alert status="info" {...rest}>
      <AlertDescription>{children}</AlertDescription>
    </Alert>
  ) : null;

function getUniqueFeatureIds(states: ColumnState[]) {
  return states.reduce<string[]>((acc, { id }) => {
    const [_, featureId] = id.split(".");
    if (featureId && !acc.includes(featureId)) {
      acc.push(featureId);
    }
    return acc;
  }, []);
}

function Page({ segment }: { segment: SegmentDTOWithTotalCompanies }) {
  const { currentEnv } = useAuthContext();
  const navigate = useNavigate();

  const segmentUpsert = useSegmentUpsertMutations(segment, {
    onSuccess: ({ id }, { action }) => {
      if (action == "create") navigate(SegmentUrl(currentEnv!, id));
    },
  });

  const {
    searchQuery,
    sorting,
    pageIndex,
    columnStates,
    companyFilter,
    isDirty,
    onReset,
  } = useCompaniesTableState(segment);

  const idNameFilter = useDebounce(searchQuery, 100);

  const filterChanged = !equal(companyFilter, segment.companyFilter);

  // We only handle sorting one column for now
  const { id: sortBy, desc: sortDesc } = sorting[0] ?? {};
  const { data, isLoading, isFetching } = useCompaniesData({
    sortBy,
    sortOrder:
      sortDesc === true ? "desc" : sortDesc === false ? "asc" : undefined,
    pageIndex,
    companyFilter: companyFilter,
    idNameFilter,
    includeDetailsForFeatureIds: getUniqueFeatureIds(columnStates ?? []),
  });

  useEffect(() => {
    segmentAnalytics.page("Companies", {
      segment: segment.system ? segment.name.toLowerCase() : "custom",
    });
  }, [segment.system, segment.name]);

  // todo: improve with issue BUC-2889
  const dependee = { type: "segment", id: segment.id } as const;
  const { data: filterCheck } = useFilterCheck(
    filterChanged ? dependee : undefined,
    companyFilter,
  );
  const canUpdateSegment = useMemo(
    () =>
      !filterChanged ||
      (filterCheck &&
        !filterCheck.cycle &&
        (!filterCheck.features.length || filterCheck.stateless)),
    [filterChanged, filterCheck],
  );

  return (
    <>
      <VisuallyHidden>
        <h1>{segment?.name}</h1>
      </VisuallyHidden>
      <HeaderLayout
        actions={
          isDirty && (
            <UpsertPopover
              canCreate={!filterCheck?.cycle}
              canUpdate={canUpdateSegment}
              entity="Segment"
              mutation={segmentUpsert}
              onReset={onReset}
            >
              {(action) => (
                <>
                  {filterChanged && (
                    <>
                      <AffectedDependencies
                        changeType="update"
                        dependee={dependee}
                        filter={companyFilter}
                      />
                      <ActiveFeaturesAlert
                        dependee={dependee}
                        filter={companyFilter}
                      />
                    </>
                  )}
                  <ConditionalAlert>
                    {action === "update" &&
                    segment.isAllSegment &&
                    filterChanged
                      ? `Filters can't be changed on the "${segment.name}" segment. Only sorting and column configuration will be saved.`
                      : null}
                  </ConditionalAlert>
                </>
              )}
            </UpsertPopover>
          )
        }
        title={
          <>
            <Box ml={-2}>
              <SegmentNavigator currentSegment={segment} />
            </Box>
            {isLoading ? (
              <AnimatedSpinner show />
            ) : (
              <MotionBox
                key="stats"
                animate={{ opacity: 1 }}
                display="flex"
                gap={4}
                initial={{ opacity: 0 }}
                transition={{ type: "ease", duration: "0.1" }}
              >
                <Text fontSize="sm" fontWeight="normal" variant="dimmed">
                  {data?.totalCount || 0}{" "}
                  {` compan${data?.totalCount === 1 ? "y" : "ies"}`}
                </Text>
                <Text fontSize="sm" fontWeight="normal" variant="dimmed">
                  {data?.metadata.totalUserCount || 0}{" "}
                  {pluralize("user", data?.metadata.totalUserCount || 0)}
                </Text>
              </MotionBox>
            )}
          </>
        }
        borderless
      >
        <>
          <Helmet>
            <title>Companies › {segment?.name}</title>
          </Helmet>
          <CompaniesTable
            data={data}
            isFetching={isFetching}
            isLoading={isLoading}
            segment={segment}
          />
        </>
      </HeaderLayout>
    </>
  );
}

export default function Companies() {
  const [segmentId, setSegmentId] = useSegmentParam();
  const { data: segments, isSuccess } = useSegments();
  const errorToast = useErrorToast();

  const allSegment = segments?.find((s) => s.isAllSegment);
  const currentSegment =
    segmentId === "all" || segmentId === null
      ? allSegment
      : segments?.find((s) => s.id === segmentId);

  useEffect(() => {
    if (segmentId && isSuccess && !currentSegment) {
      errorToast({
        description: `Could not find the segment, redirecting to All`,
        duration: 4000,
      });

      if (allSegment) {
        setSegmentId(allSegment.id);
      }
    }
  }, [
    segmentId,
    isSuccess,
    currentSegment,
    errorToast,
    allSegment,
    setSegmentId,
  ]);

  if (!currentSegment) return null;

  return <Page key={currentSegment.id} segment={currentSegment} />;
}
