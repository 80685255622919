import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { CompaniesQuery, CompanyList } from "@bucketco/shared/companyAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

import { companyQueryKeys } from "./companyQueryKeys";

export type CompanySearchType = { id: string; name: string | null };

export function useCompaniesSearch(
  search?: string,
  query: Omit<CompaniesQuery, "envId" | "idNameFilter"> = {},
  options?: Omit<
    UseQueryOptions<
      CompanyList,
      AxiosError<ErrorResponse>,
      CompanySearchType[]
    >,
    "queryKey" | "queryFn"
  >,
) {
  const { appId, envId } = useCurrentEnv();
  const body = {
    pageSize: 50,
    idNameFilter: search,
    ...query,
  };

  return useQuery<CompanyList, AxiosError<ErrorResponse>, CompanySearchType[]>({
    queryKey: companyQueryKeys.companyNames(appId, envId, body),
    queryFn: () =>
      api
        .post<"/apps/:appId/companies/search">(
          `/apps/${appId}/companies/search`,
          body,
          {
            params: { envId: envId! },
          },
        )
        .then((res) => res.data),
    select: (payload) => payload.data.map(({ id, name }) => ({ id, name })),
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    placeholderData: keepPreviousData,
    ...options,
    enabled: !!appId && !!envId && options?.enabled !== false,
  });
}
