import { Outlet, useParams } from "react-router-dom";
import { Flex } from "@chakra-ui/react";

import { EnvironmentProvider } from "@/common/hooks/useCurrentEnv";
import { useSearchParam } from "@/common/hooks/useSearchParam";
import { FeatureConfigs as FeatureConfigsComponent } from "@/feature/components/FeatureConfigs";
import { SetupInstructions } from "@/feature/components/SetupInstructions";
import FeatureSideBarWrapper from "../components/FeatureSidebarWrapper";

export default function FeatureConfigs() {
  const { envId: envIdParam, featureId } = useParams();
  const [envId] = useSearchParam("environment", {
    fallback: envIdParam!,
  });

  return (
    <FeatureSideBarWrapper>
      <Flex direction="column" px={6} py={4} w="full">
        <SetupInstructions featureId={featureId} />
        <EnvironmentProvider envId={envId}>
          <FeatureConfigsComponent />
        </EnvironmentProvider>
        <Outlet />
      </Flex>
    </FeatureSideBarWrapper>
  );
}
