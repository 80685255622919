import { forwardRef } from "react";
import { RiBracketsLine } from "react-icons/ri";
import { Tag, TagLabelProps, TagProps } from "@chakra-ui/react";

import { UIFilter } from "@bucketco/shared/filter";

import CompanyAttributeIcon from "@/common/assets/company-attribute-icon.svg?react";
import UserAttributeIcon from "@/common/assets/user-attribute-icon.svg?react";
import { FeatureTargetingFilterItem } from "@/common/components/filters/FeatureTargetingFilterItem";
import TagRemove from "@/common/components/TagRemove";

import { AttributeFilterItem } from "./AttributeFilterItem";
import { CompanyFeatureMetricFilterItem } from "./CompanyFeatureMetricFilterItem";
import { EmptyFilterItem } from "./EmptyFilterItem";
import { SegmentFilterItem } from "./SegmentFilterItem";

type FilterItemTagLabelProps = Omit<TagLabelProps, "filter"> & {
  filter: UIFilter | undefined;
  compact?: boolean;
  showIcon?: boolean;
};

export const FilterItemTagLabel = ({
  filter,
  showIcon = true,
  compact = false,
  ...rest
}: FilterItemTagLabelProps) => {
  return !filter ? (
    <EmptyFilterItem showIcon={showIcon} {...rest} />
  ) : filter.type === "companyAttribute" ? (
    <AttributeFilterItem
      compact={compact}
      filter={filter}
      icon={showIcon ? CompanyAttributeIcon : undefined}
      {...rest}
    />
  ) : filter.type === "otherContext" ? (
    <AttributeFilterItem
      compact={compact}
      filter={filter}
      icon={showIcon ? RiBracketsLine : undefined}
      {...rest}
    />
  ) : filter.type === "userAttribute" ? (
    <AttributeFilterItem
      compact={compact}
      filter={filter}
      icon={showIcon ? UserAttributeIcon : undefined}
      {...rest}
    />
  ) : filter.type === "featureMetric" ? (
    <CompanyFeatureMetricFilterItem filter={filter} {...rest} />
  ) : filter.type === "segment" ? (
    <SegmentFilterItem
      compact={compact}
      filter={filter}
      showIcon={showIcon}
      {...rest}
    />
  ) : filter.type === "featureTargeting" ? (
    <FeatureTargetingFilterItem filter={filter} {...rest} />
  ) : null;
};

type FilterItemProps = Omit<TagProps, "filter"> & {
  filter: UIFilter | undefined;
  size?: TagProps["size"];
  showIcon?: boolean;
  showRemove?: boolean;
  compact?: boolean;
  onClick?: () => void;
  onRemove?: () => void;
};

export const FilterItem = forwardRef(
  (
    {
      filter,
      size = "xs",
      showIcon = true,
      showRemove = false,
      compact = false,
      onClick,
      onRemove = () => {},
      ...props
    }: FilterItemProps & Omit<TagProps, "filter">,
    ref,
  ) => {
    return (
      <Tag
        ref={ref}
        overflow="hidden"
        pos="relative"
        role="group"
        size={compact ? `${size}Compact` : size}
        variant={compact ? "unstyled" : onClick ? "clickable" : "subtle"}
        onClick={onClick}
        {...props}
      >
        <FilterItemTagLabel
          compact={compact}
          filter={filter}
          showIcon={showIcon}
        />
        {showRemove && <TagRemove onRemove={onRemove} />}
      </Tag>
    );
  },
);

FilterItem.displayName = "AttributeFilterItem";
