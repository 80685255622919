import { RiArrowDownSLine } from "react-icons/ri";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  MenuProps,
  Text,
} from "@chakra-ui/react";

import RollingTimeWindowIcon from "@/common/assets/rolling-time-window-icon.svg?react";
import MenuDescription from "@/common/components/MenuDescription";

export const rollingWindowLabels = {
  1: "1 day",
  7: "1 week",
  14: "2 weeks",
  30: "30 days",
} as const;

export type RollingWindowPeriod = keyof typeof rollingWindowLabels;

export type RollingTimeWindowPickerProps = Omit<MenuProps, "children"> & {
  value: RollingWindowPeriod;
  onChange: (value: RollingWindowPeriod) => void;
};

export default function RollingTimeWindowPicker({
  value,
  onChange,
  ...rest
}: RollingTimeWindowPickerProps) {
  return (
    <Menu {...rest}>
      <MenuButton
        as={Button}
        leftIcon={
          <Text fontSize="lg" variant="dimmed">
            <RollingTimeWindowIcon />
          </Text>
        }
        rightIcon={
          <Box fontSize="xl" mr={-2}>
            <RiArrowDownSLine />
          </Box>
        }
        size="sm"
        variant="input"
      >
        {rollingWindowLabels[value]}
      </MenuButton>
      <MenuList>
        <MenuDescription color="dimmed">
          Select rolling time window
        </MenuDescription>
        <MenuOptionGroup
          value={String(value)}
          onChange={(val) =>
            onChange(Number(val) as keyof typeof rollingWindowLabels)
          }
        >
          <MenuItemOption value="1">{rollingWindowLabels[1]}</MenuItemOption>
          <MenuItemOption value="7">{rollingWindowLabels[7]}</MenuItemOption>
          <MenuItemOption value="14">{rollingWindowLabels[14]}</MenuItemOption>
          <MenuItemOption value="30">{rollingWindowLabels[30]}</MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}
