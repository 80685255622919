import { useNavigate } from "react-router-dom";
import { Button, ButtonProps } from "@chakra-ui/react";

type Props = ButtonProps & {
  shouldNavigate?: boolean;
  shouldGoBack?: boolean;
};

export default function ModalCancelButton({
  shouldNavigate = true,
  shouldGoBack = true,
  ...rest
}: Props) {
  const navigate = useNavigate();

  return (
    <Button
      type="button"
      variant="ghostDimmed"
      onClick={
        shouldNavigate
          ? () => (shouldGoBack ? navigate(-1) : navigate(".."))
          : undefined
      }
      {...rest}
    >
      Cancel
    </Button>
  );
}
