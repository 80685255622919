import React, { useMemo, useState } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { RiAddLine, RiDeleteBinLine, RiPencilLine } from "react-icons/ri";
import { useParams } from "react-router";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Flex,
  HStack,
  IconButton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import uniq from "lodash/uniq";

import {
  FeatureConfigVariant,
  FeatureConfigVariantWithEnvironmentRules,
  UpdateFeatureConfig,
  UpdateFeatureConfigSchema,
} from "@bucketco/shared/featureConfigAPI";

import CardContainer from "@/common/components/CardContainer";
import { ConfirmationDialog } from "@/common/components/ConfirmationDialog";
import { EnvironmentPicker } from "@/common/components/EnvironmentPicker";
import FormReset from "@/common/components/Form/FormReset";
import { FormRootError } from "@/common/components/Form/FormRootError";
import FormSubmit from "@/common/components/Form/FormSubmit";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import { RuleSimpleForm } from "@/common/components/Rule/RuleSimpleForm";
import { useFormMutationSubmitHandler } from "@/common/hooks/useApiForm";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import { useSearchParam } from "@/common/hooks/useSearchParam";
import { FeatureConfigModal } from "@/feature/components/FeatureConfigModal";
import { useFeatureConfigData } from "@/feature/data/useFeatureConfigData";
import { useFeatureConfigUpdateMutation } from "@/feature/data/useFeatureConfigUpdateMutation";

type ConfigProps = {
  baseName: `variants.${number}`;
  onEdit: () => void;
  onDelete: () => void;
  onSetDefault: () => void;
};

const Config = ({ baseName, onEdit, onDelete, onSetDefault }: ConfigProps) => {
  const { envId } = useCurrentEnv();
  const {
    variant,
    environmentRules,
  }: FeatureConfigVariantWithEnvironmentRules = useWatch<UpdateFeatureConfig>({
    name: baseName,
  });

  if (!variant) return null;

  const isDefault = environmentRules[envId!]?.default;
  return (
    <CardContainer gap={1} pb={isDefault ? 2 : 1}>
      <Flex align="center" gap={4} justify="space-between" px={2}>
        <HStack overflow="hidden" spacing={2}>
          <HStack align="baseline" as="p" overflow="hidden" spacing={2}>
            <Text as="span" fontFamily="code" fontWeight="medium">
              {variant.key}
            </Text>
            <Text
              as="span"
              color="dimmed"
              fontFamily="code"
              fontSize="xs"
              isTruncated
            >
              {variant.payload}
            </Text>
          </HStack>
          <IconButton
            aria-label="Edit config"
            color="dimmed"
            icon={<RiPencilLine size={16} />}
            size="xs"
            variant="ghost"
            onClick={onEdit}
          />
        </HStack>
        <HStack spacing={2}>
          {isDefault ? (
            <Badge borderRadius="full" colorScheme="brand">
              Default
            </Badge>
          ) : (
            <IconButton
              aria-label="Delete config"
              color="dimmed"
              icon={<RiDeleteBinLine size={16} />}
              size="xs"
              variant="ghost"
              onClick={onDelete}
            />
          )}
        </HStack>
      </Flex>

      {!isDefault && (
        <Card p={4} w="full">
          <HStack
            h="8"
            position="absolute"
            right={3}
            spacing={2}
            top={3}
            zIndex={1}
          >
            <Button size="xs" variant="outline" onClick={onSetDefault}>
              Set default
            </Button>
            <InfoIconTooltip
              _icon={{ verticalAlign: "top", height: "100%" }}
              text="Config values are evaluated in order of priority: individual users first, then companies, and finally segments."
            />
          </HStack>
          <RuleSimpleForm baseName={`${baseName}.environmentRules.${envId}`} />
        </Card>
      )}
    </CardContainer>
  );
};

export const FeatureConfigs = () => {
  const { envId: envIdParam, featureId } = useParams();
  const [envId, setEnvId] = useSearchParam("environment", {
    fallback: envIdParam!,
  });

  const errorToast = useErrorToast();
  const configDialog = useDisclosure();
  const unsavedChangesDialog = useDisclosure();
  const [selectedVariant, setSelectedVariant] = useState<{
    variant: FeatureConfigVariant;
    index: number;
  } | null>(null);
  const { data: configData } = useFeatureConfigData(featureId);

  const [expectedVersionIds, defaultRuleIndexes] = useMemo(() => {
    return [
      configData ? Object.values(configData).map((config) => config.id) : [],
      configData
        ? uniq(
            Object.values(configData)
              .flatMap(({ variants }) =>
                variants.findIndex(
                  ({ rule: { default: isDefault } }) => isDefault,
                ),
              )
              .filter((index) => index > -1),
          )
        : [],
    ];
  }, [configData]);

  const currentConfig = configData?.[envId!];

  const form = useForm<UpdateFeatureConfig>({
    resolver: zodResolver(UpdateFeatureConfigSchema),
    values: {
      variants:
        currentConfig?.variants.map((item) => ({
          variant: item.variant,
          environmentRules: {
            [envId!]: item.rule,
          },
        })) ?? [],
      changeDescription: "",
    },
  });

  const { fields, append, remove, update } = useFieldArray({
    control: form.control,
    name: "variants",
  });

  const mutation = useFeatureConfigUpdateMutation(
    featureId!,
    expectedVersionIds.length ? (expectedVersionIds as [string]) : undefined,
  );

  const onSubmit = useFormMutationSubmitHandler(form, mutation, undefined, {
    successToast: "Feature config updated successfully",
    errorToast: "Failed to update feature config",
  });

  const handleConfigSubmit = (variant: FeatureConfigVariant) => {
    if (selectedVariant) {
      // Update existing variant
      update(selectedVariant.index, {
        ...fields[selectedVariant.index],
        variant,
      });
    } else {
      // Add new variant
      append({
        variant: {
          ...variant,
        },
        environmentRules: {
          [envId!]: {
            segmentIds: [],
            companyIds: [],
            userIds: [],
            default: fields.length === 0, // Set first variant as default
          },
        },
      });
    }
    configDialog.onClose();
    setSelectedVariant(null);
  };

  const handleDelete = (index: number) => {
    if (defaultRuleIndexes.includes(index)) {
      errorToast({
        description:
          "This config value is set as default in one or more environments. Please set a different value as default before deleting this one.",
        title: "Cannot delete value",
        duration: 5000,
      });
      return;
    }

    remove(index);
  };

  const handleSetDefault = (index: number) => {
    fields.forEach((field, i) => {
      update(i, {
        ...field,
        variant: {
          ...field.variant,
        },
        environmentRules: {
          [envId!]: {
            ...field.environmentRules[envId!],
            default: i === index,
          },
        },
      });
    });
  };

  const [pendingEnvId, setPendingEnvId] = useState<string | null>(null);

  const handleEnvironmentChange = (newEnvId: string | null) => {
    if (form.formState.isDirty) {
      setPendingEnvId(newEnvId);
      unsavedChangesDialog.onOpen();
    } else {
      setEnvId(newEnvId);
    }
  };

  const handleSaveAndSwitchEnvironment = async () => {
    await onSubmit();
    if (pendingEnvId) {
      setEnvId(pendingEnvId);
      setPendingEnvId(null);
    }
    unsavedChangesDialog.onClose();
  };

  const handleDiscardAndSwitchEnvironment = () => {
    if (pendingEnvId) {
      setEnvId(pendingEnvId);
      setPendingEnvId(null);
    }
    form.reset();
    unsavedChangesDialog.onClose();
  };

  return (
    <FormProvider {...form}>
      <Flex align="center" justify="space-between" mb={4}>
        <Text fontWeight="medium">Config values</Text>
        <EnvironmentPicker value={envId} onChange={handleEnvironmentChange} />
      </Flex>
      <Flex
        align="flex-start"
        as="form"
        direction="column"
        gap={2}
        onSubmit={onSubmit}
      >
        {fields.map((field, index) => (
          <Config
            key={field.id}
            baseName={`variants.${index}`}
            onDelete={() => handleDelete(index)}
            onEdit={() => {
              const variant = form.getValues(`variants.${index}.variant`);
              setSelectedVariant({ variant, index });
              configDialog.onOpen();
            }}
            onSetDefault={() => handleSetDefault(index)}
          />
        ))}

        <Button
          color="dimmed"
          leftIcon={<RiAddLine />}
          variant="ghost"
          onClick={() => {
            setSelectedVariant(null);
            configDialog.onOpen();
          }}
        >
          Add config value
        </Button>

        <FormRootError />

        <ButtonGroup>
          <FormSubmit isLoading={mutation.isPending} />
          <FormReset />
        </ButtonGroup>
      </Flex>

      <FeatureConfigModal
        key={selectedVariant?.index}
        existingKeys={fields
          .map((field) => field.variant.key)
          .filter((key) => key !== selectedVariant?.variant.key)}
        isOpen={configDialog.isOpen}
        variant={selectedVariant?.variant}
        onClose={() => {
          configDialog.onClose();
          setSelectedVariant(null);
        }}
        onSubmit={handleConfigSubmit}
      />

      <ConfirmationDialog
        cancelLabel="Discard"
        closeLabel="Cancel"
        confirmLabel="Save"
        description={
          <Text>
            You have unsaved changes. Would you like to save or discard them
            before switching environment?
          </Text>
        }
        isLoading={mutation.isPending}
        isOpen={unsavedChangesDialog.isOpen}
        title="Unsaved Changes"
        showCloseAsButton
        onCancel={handleDiscardAndSwitchEnvironment}
        onClose={unsavedChangesDialog.onClose}
        onConfirm={handleSaveAndSwitchEnvironment}
      />
    </FormProvider>
  );
};
