import { useRef } from "react";
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Button,
  ButtonGroup,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

import { AdminOrgMember } from "@bucketco/shared/adminAPI";
import { Org } from "@bucketco/shared/organizationAPI";

import { useAdminMemberDeleteMutation } from "@/admin/data/useAdminMemberDeleteMutation";

export function AdminMemberActions({
  org,
  member,
}: {
  org: Org;
  member: AdminOrgMember;
}) {
  const removeMutation = useAdminMemberDeleteMutation(org.id);
  const confirmMemberRemovalDisclosure = useDisclosure();

  function handleMemberRemove(member: AdminOrgMember) {
    // Don't require confirmation for admins
    if (member.isAdmin) {
      removeMutation.mutate({ memberId: member.id });
    } else {
      confirmMemberRemovalDisclosure.onOpen();
    }
  }

  return (
    <>
      <ButtonGroup>
        <Button
          colorScheme="red"
          size="sm"
          onClick={() => handleMemberRemove(member)}
        >
          Remove
        </Button>
      </ButtonGroup>
      <ConfirmMemberRemoveDialog
        disclosure={confirmMemberRemovalDisclosure}
        member={member}
        org={org}
        removeMutation={removeMutation}
      />
    </>
  );
}

function ConfirmMemberRemoveDialog({
  org,
  member,
  disclosure,
  removeMutation,
}: {
  org: Org;
  member: AdminOrgMember;
  disclosure: ReturnType<typeof useDisclosure>;
  removeMutation: ReturnType<typeof useAdminMemberDeleteMutation>;
}) {
  const cancelRef = useRef(null);

  return (
    <AlertDialog
      isOpen={disclosure.isOpen}
      leastDestructiveRef={cancelRef}
      onClose={disclosure.onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>Remove organization member</AlertDialogHeader>
          <AlertDialogBody align="flex-start" as={VStack} spacing={4}>
            <Text as="p">
              Are you sure you want to remove <strong>{member.name}</strong>{" "}
              from <strong>{org.name}</strong>?
            </Text>

            <Alert alignItems="flex-start" status="warning">
              <AlertIcon />

              <VStack alignItems="flex-start" spacing={4}>
                <Text as="p">You can&apos;t undo this action afterwards.</Text>
              </VStack>
            </Alert>
          </AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              <Button
                ref={cancelRef}
                variant="ghostDimmed"
                onClick={disclosure.onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                type="submit"
                onClick={() => removeMutation.mutate({ memberId: member.id })}
              >
                Remove
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
