import { RiCloseLine } from "react-icons/ri";
import { IconButton, IconButtonProps } from "@chakra-ui/react";

interface TagRemoveProps extends Omit<IconButtonProps, "aria-label" | "icon"> {
  onRemove: () => void;
  size?: IconButtonProps["size"];
}

export default function TagRemove({
  onRemove,
  size = "2xs",
  ...props
}: TagRemoveProps) {
  return (
    <IconButton
      aria-label="Remove filter"
      icon={<RiCloseLine size={16} />}
      ml={1}
      mr={-2}
      size={size}
      variant="ghostDimmed"
      onClick={onRemove}
      {...props}
    />
  );
}
