const knownPlurals: Record<string, string> = {
  is: "are",
  use: "uses",
  company: "companies",
};

/**
 * Pluralizes a word based on the specified count.
 * If a plural is provided, it will be used instead of appending an "s".
 */
export default function pluralize(
  singular: string,
  count: number,
  plural?: string,
) {
  if (count === 1) {
    return singular;
  }

  return plural || knownPlurals[singular] || `${singular}s`;
}
