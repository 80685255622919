import { useSearchParams } from "react-router-dom";
import { Badge, Box, Td, Tr } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { flexRender, HeaderContext } from "@tanstack/react-table";

import {
  FeatureListColumn,
  FeatureListItem,
  FeatureListQuery,
  FeatureListSortBy,
} from "@bucketco/shared/featureAPI";
import { FeatureViewDTO } from "@bucketco/shared/featureViewAPI";
import { SCREENSHOT_SELECTOR_ID } from "@bucketco/shared/screenshot";
import { ColumnState } from "@bucketco/shared/types/columns";
import { filterMap } from "@bucketco/shared/utils/array";

import { useFeatureViewData } from "@/app/data/useFeatureViewData";
import SimpleTable from "@/common/components/SimpleTable";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { getId } from "@/common/utils/datatable";
import dayjs from "@/common/utils/dayjs";
import useActiveSegment from "@/company/data/useActiveSegment";
import { FeatureDisplay } from "@/feature/components/FeatureDisplay";
import { useFeaturesColumns } from "@/feature/components/FeaturesTable";
import { featureQueryKeys } from "@/feature/data/featureQueryKeys";

const isDatePastWeek = (date: string | null) =>
  !!date && dayjs(date).isSameOrAfter(dayjs().subtract(7, "days"));

const isNew = (f: FeatureListItem) => isDatePastWeek(f.createdAt);

function NewBadge() {
  return <Badge colorScheme="orange">New</Badge>;
}

export default function FeatureViewReportScreenshot() {
  const [searchParams] = useSearchParams();
  const { appId, envId } = useCurrentEnv();

  const { view } = Object.fromEntries(searchParams.entries());

  const {
    data: featureView,
    isLoading: isFeatureViewLoading,
    isError: isFeatureViewError,
  } = useFeatureViewData(view);
  const activeSegment = useActiveSegment();

  const sortBy = featureView?.columnSortKey ?? ("name" as FeatureListSortBy);
  const sortOrder = (
    featureView?.columnSortDesc ? "desc" : "asc"
  ) as FeatureListQuery["sortOrder"];

  const params = {
    view,
    sortBy,
    sortOrder,
    envId: envId!,
    subsegment: activeSegment?.id,
  };

  const { data, isPending, isLoading, isError } = useQuery({
    queryKey: featureQueryKeys.list(appId, envId, params),
    queryFn: () =>
      api
        .get<"/apps/:appId/features">(`/apps/${appId}/features`, {
          params: {
            ...params,
            envId: envId!,
          },
        })
        .then((res) => res.data.data),
    enabled:
      !!appId &&
      !!envId &&
      !!activeSegment &&
      !isFeatureViewLoading &&
      featureView !== undefined,
  });

  if (isError || isFeatureViewError) {
    return null;
  }

  return (
    <Box aria-busy={isPending || isLoading}>
      {data && featureView && (
        <Table features={data} featureView={featureView} />
      )}
    </Box>
  );
}

const DEFAULT_COLUMNS: ColumnState<FeatureListColumn>[] = [
  { id: "name", shown: true },
  { id: "key", shown: true },
  { id: "stage", shown: true },
  { id: "productionRolloutTargetingRules", shown: true },
  { id: "goals", shown: true },
  { id: "createdAt", shown: true },
];

function Table({
  features,
  featureView,
}: {
  features: FeatureListItem[];
  featureView?: FeatureViewDTO;
}) {
  const colProps: React.HTMLProps<HTMLTableColElement>[] = [
    { width: "428px" },
    { width: "150px" },
    { width: "150px" },
    { width: "200px" },
    { width: "200px" },
    { width: "150px" },
  ];

  const featureColumns = useFeaturesColumns();

  if (!featureView) {
    return null;
  }

  const allColumns = featureView.columns?.length
    ? featureView.columns
    : DEFAULT_COLUMNS;

  const columns = filterMap(allColumns, (column) => {
    const definition = featureColumns.find(
      (c) => getId(c as any) === column.id,
    );
    return definition && column.shown
      ? {
          ...column,
          header: definition.header,
        }
      : null;
  }).slice(0, 5);

  return (
    <SimpleTable
      colProps={colProps}
      columns={columns.map((c) =>
        flexRender(c.header, {} as HeaderContext<FeatureListItem, unknown>),
      )}
      id={SCREENSHOT_SELECTOR_ID}
      layout="fixed"
      rows={features}
      rowTemplate={(feature, index) => (
        <FeatureRow key={index} columns={columns} feature={feature} />
      )}
      size="md"
    />
  );
}

function FeatureRow({
  feature,
  columns,
}: {
  feature: FeatureListItem;
  columns: ColumnState[];
}) {
  const isFeatureNew = isNew(feature);

  const featureColumns = useFeaturesColumns();

  return (
    <Tr>
      {columns.map((column, idx) => {
        if (column.id === "name") {
          return (
            <Td key={idx} maxW="100px" paddingInlineEnd={0}>
              <FeatureDisplay
                feature={feature}
                icon={isFeatureNew ? <NewBadge /> : undefined}
                showIcon={isFeatureNew}
                size="lg"
              />
            </Td>
          );
        }

        const definition = featureColumns.find(
          (c) => getId(c as any) === column.id,
        );

        if (!definition?.cell || typeof definition.cell === "string") {
          return null;
        }

        const id = getId(definition as any);
        if (id === null) return null;

        return (
          <Td key={idx}>
            {definition.cell({
              getValue: () => feature[id as FeatureListColumn],
              row: { original: feature },
            } as any)}
          </Td>
        );
      })}
    </Tr>
  );
}
