import { useQuery } from "@tanstack/react-query";

import { UserName } from "@bucketco/shared/userAPI";

import { commonQueryKeys } from "@/common/data/commonQueryKeys";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

function usersWithNames(
  ids: string[] | undefined,
  resolvedIds: UserName[] | undefined,
) {
  return ids?.map((id) => {
    const prevUser = resolvedIds?.find((c) => c.id === id);
    return {
      name: null,
      email: null,
      ...prevUser,
      id,
    };
  });
}

export function useUserNamesData(ids?: string[]) {
  const { appId, envId } = useCurrentEnv();
  return useQuery({
    queryKey: commonQueryKeys.userNames(appId, envId, ids),
    queryFn: () =>
      api
        .post<`/apps/:appId/user-names`>(
          `/apps/${appId}/user-names`,
          { ids: ids as [string, ...string[]] },
          {
            params: { envId: envId! },
          },
        )
        .then((res) => usersWithNames(ids, res.data)),
    enabled: !!appId && !!envId && !!ids?.length,
    placeholderData: (previousData) => usersWithNames(ids, previousData),
  });
}
