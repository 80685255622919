import { Control, FormProvider, useFieldArray, useForm } from "react-hook-form";
import { RiArrowLeftLine } from "react-icons/ri";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Button,
  Flex,
  HStack,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import omit from "lodash/omit";

import { ErrorResponse } from "@bucketco/shared/api";
import {
  EnvironmentDTO,
  EnvironmentListItemDTO,
} from "@bucketco/shared/environmentAPI";
import {
  StageDTO,
  StagePostArgs,
  StagePostSchema,
} from "@bucketco/shared/stageAPI";
import { GlobalSettingsUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import FormInput from "@/common/components/Form/FormInput";
import { RulesForm } from "@/common/components/Rule/RulesForm";
import { useFormMutationSubmitHandler } from "@/common/hooks/useApiForm";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { useEnvironmentsData } from "@/environment/data/useEnvironmentsData";
import { FormStageColorPicker } from "@/stage/components/FormStageColorPicker";
import { stageQueryKeys } from "@/stage/data/stageQueryKeys";
import { useStageData } from "@/stage/data/useStageData";
import {
  useStageCreateMutation,
  useStageUpdateMutation,
} from "@/stage/data/useStagesMutations";

const StageEnvironmentForm = ({
  baseName,
  environment,
  control,
}: {
  baseName: `stageEnvironments.${number}`;
  environment: EnvironmentListItemDTO;
  control: Control<StagePostArgs>;
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${baseName}.customRules`,
  });

  return (
    <RulesForm
      append={append}
      baseName={baseName}
      environment={environment}
      fields={fields}
      remove={remove}
    />
  );
};

type StageFormProps = {
  environments: EnvironmentDTO[];
  stage?: StageDTO;
};

const StageForm = ({ environments, stage }: StageFormProps) => {
  const { appId } = useCurrentEnv();
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const createStageMutation = useStageCreateMutation();
  const updateStageMutation = useStageUpdateMutation();
  const form = useForm<StagePostArgs>({
    resolver: zodResolver(StagePostSchema),
    defaultValues: {
      name: stage?.name ?? "",
      colorIndex: stage?.colorIndex ?? 0,
      stageEnvironments: environments.map((env) => {
        const stageEnv = stage?.stageEnvironments?.find(
          (se) => se.environment.id === env.id,
        );
        return {
          environmentId: env.id,
          targetingMode: stageEnv?.targetingMode ?? "none",
          customRules:
            stageEnv?.customRules.map((rule) => omit(rule, "id")) ?? [],
          segmentIds: stageEnv?.segmentIds ?? [],
          companyIds: stageEnv?.companyIds ?? [],
          userIds: stageEnv?.userIds ?? [],
        };
      }),
    },
  });
  const { fields } = useFieldArray({
    control: form.control,
    name: "stageEnvironments",
  });

  const handleSubmit = useFormMutationSubmitHandler(
    form,
    // Cast to avoid errors as patch is less strict than post but requires an id
    (stage ? updateStageMutation : createStageMutation) as UseMutationResult<
      StageDTO,
      AxiosError<ErrorResponse>,
      StagePostArgs
    >,
    (newStage) => {
      queryClient.setQueryData(
        stageQueryKeys.single(appId, newStage.id),
        newStage,
      );
      segmentAnalytics.track(!stage ? "Stage Created" : "Stage Updated", {
        name: newStage.name,
      });
      toast({
        title: !stage
          ? `Stage "${newStage.name}" created`
          : `Stage "${newStage.name}" updated`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      navigate(-1);
    },
    {
      prepareVariables: (values) => {
        return {
          id: stage?.id,
          ...values,
        };
      },
    },
  );

  return (
    <FormProvider {...form}>
      <Flex
        alignItems="flex-start"
        as="form"
        direction="column"
        gap={4}
        w="3xl"
        onSubmit={handleSubmit}
      >
        <Text as="label" fontWeight="medium" htmlFor="stage-name">
          Stage name
        </Text>
        <HStack alignItems="flex-start" justifyContent="flex-start">
          <FormStageColorPicker name="colorIndex" />
          <FormInput id="stage-name" name="name" placeholder="Stage name" />
        </HStack>
        <Text as="label" fontWeight="medium">
          Rules
        </Text>
        {fields.map((field, index) => (
          <StageEnvironmentForm
            key={field.id}
            baseName={`stageEnvironments.${index}`}
            control={form.control}
            environment={environments[index]}
          />
        ))}
        <Button type="submit" variant="primary">
          Save
        </Button>
      </Flex>
    </FormProvider>
  );
};

export const Stage = ({ stageId }: { stageId: string }) => {
  const { currentEnv } = useAuthContext();
  const {
    data: stage,
    isLoading: isStageLoading,
    isSuccess: isStageSuccess,
  } = useStageData(stageId === "new" ? undefined : stageId);
  const { data: environments = [], isLoading: isEnvironmentsLoading } =
    useEnvironmentsData();

  return (
    <Flex alignItems="flex-start" direction="column" gap={4}>
      <Button
        as={RouterLink}
        leftIcon={<RiArrowLeftLine />}
        to={GlobalSettingsUrl(currentEnv!, "app-stages")}
        variant="ghostDimmed"
      >
        Back to stages
      </Button>
      {isEnvironmentsLoading ? (
        <Spinner />
      ) : stageId === "new" ? (
        <StageForm environments={environments} />
      ) : isStageLoading ? (
        <Spinner />
      ) : isStageSuccess ? (
        <StageForm environments={environments} stage={stage} />
      ) : (
        <Text>Error loading stage</Text>
      )}
    </Flex>
  );
};
