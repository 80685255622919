import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import {
  FeatureConfig,
  UpdateFeatureConfig,
} from "@bucketco/shared/featureConfigAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import api from "@/common/utils/api";

import { featureQueryKeys } from "./featureQueryKeys";

export function useFeatureConfigUpdateMutation(
  featureId: string,
  expectedVersionIds?: [string, ...string[]],
) {
  const { currentApp } = useAuthContext();
  const queryClient = useQueryClient();

  return useMutation<
    FeatureConfig,
    AxiosError<ErrorResponse>,
    UpdateFeatureConfig
  >({
    mutationFn: (data) =>
      api
        .put<"/apps/:appId/features/:featureId/config">(
          `/apps/${currentApp?.id}/features/${featureId}/config`,
          data,
          {
            params: {
              expectedVersionIds,
            },
          },
        )
        .then((response) => response.data),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: featureQueryKeys.config(currentApp?.id, featureId),
      });
      queryClient.invalidateQueries({
        queryKey: featureQueryKeys.configVersions(currentApp?.id, featureId),
      });
    },
  });
}
