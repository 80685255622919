import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { APIResponse, ErrorResponse } from "@bucketco/shared/api";
import {
  FeedbackTimelineItem,
  FeedbackTimelineQueryType,
} from "@bucketco/shared/feedbackAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

import { feedbackQueryKeys } from "./feedbackQueryKeys";

export function useFeatureFeedbackTimelineData(
  query: FeedbackTimelineQueryType,
): UseQueryResult<
  APIResponse<FeedbackTimelineItem[]>,
  AxiosError<ErrorResponse>
> {
  const { envId, appId } = useCurrentEnv();

  return useQuery({
    queryKey: feedbackQueryKeys.timeline(appId, envId, query),
    queryFn: async () =>
      api
        .get<"/apps/:appId/feedback/timeline">(
          `/apps/${appId!}/feedback/timeline`,
          {
            params: query,
          },
        )
        .then((res) => res.data),
    enabled: Boolean(appId),
  });
}
