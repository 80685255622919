import { RiExternalLinkLine } from "react-icons/ri";
import { Button, HStack, Link, Text } from "@chakra-ui/react";

import SlackHashLogo from "@/common/assets/slack-hash-logo.svg?react";
import useSlackConnectionUrl from "@/common/hooks/useSlackConnectionUrl";

export function ConnectWithSlack() {
  const connectionUrl = useSlackConnectionUrl();

  return (
    <HStack spacing={3}>
      <Button
        as="a"
        href={connectionUrl}
        leftIcon={<SlackHashLogo height="16px" width="16px" />}
        size="sm"
        variant="outline"
      >
        Connect to Slack
      </Button>
      <Link
        color="dimmed"
        href="https://docs.bucket.co/integrations/slack"
        target="_blank"
        textDecoration="none"
      >
        <HStack>
          <Text fontSize="sm">Open docs</Text>
          <RiExternalLinkLine size={14} />
        </HStack>
      </Link>
    </HStack>
  );
}
