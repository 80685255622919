import { Link as RouterLink } from "react-router-dom";
import { Button, Center, Flex, Text } from "@chakra-ui/react";

import FeatureIcon from "@/common/assets/sad-bucket.svg?react";

export default function InvalidRoute() {
  return (
    <Center flexDirection="column" minHeight="100vh" padding="4" width="100%">
      <Flex align="center" direction="column" gap={6}>
        <FeatureIcon height="80px" />
        <Text
          fontSize="md"
          maxW="2xs"
          sx={{ textWrap: "balance" }}
          textAlign="center"
        >
          We couldn&apos;t find the page you were looking for.
        </Text>
        <Button as={RouterLink} to="/" variant="outline">
          Go back home
        </Button>
      </Flex>
    </Center>
  );
}
