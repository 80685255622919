import React, { useMemo, useState } from "react";
import {
  RiCloseLine,
  RiErrorWarningLine,
  RiFlashlightLine,
  RiSearchLine,
} from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";
import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  BoxProps,
  Button,
  Code,
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  Link,
  List,
  ListItem,
  Tbody,
  Td,
  Text,
  Tr,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import isEqual from "lodash/isEqual";
import uniqWith from "lodash/uniqWith";

import { DebugEvent, ValidDebugEventTypes } from "@bucketco/shared/eventAPI";
import { GlobalSettingsUrl, NewFeatureUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import PointerPressIcon from "@/common/assets/pointer-press.svg?react";
import AnimatedSpinner from "@/common/components/AnimatedSpinner";
import { CompanyAutocompleteSelect } from "@/common/components/Autocomplete/CompanyAutocompleteSelect";
import { FeatureAutocompleteSelect } from "@/common/components/Autocomplete/FeatureAutocompleteSelect";
import { UserAutocompleteSelect } from "@/common/components/Autocomplete/UserAutocompleteSelect";
import { DocumentationButton } from "@/common/components/CommonIntegrationButtons";
import CompanyDisplay from "@/common/components/CompanyDisplay";
import { DataTable } from "@/common/components/DataTable/DataTable";
import {
  DebugEventTypeDisplay,
  DebugEventTypeSelect,
} from "@/common/components/DebugEventTypeSelect";
import EmptyState from "@/common/components/EmptyState";
import InfiniteAnimation from "@/common/components/InfiniteAnimation";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import SectionDescription from "@/common/components/SectionDescription";
import { SectionHeading } from "@/common/components/SectionHeading";
import SidebarLayout, {
  SidebarContainer,
  SidebarSection,
  SidebarTable,
} from "@/common/components/SidebarLayout";
import { SwitchButtonGroup } from "@/common/components/SwitchButtonGroup";
import TimestampCell from "@/common/components/TimestampCell";
import { UserDisplay } from "@/common/components/UserDisplay";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import {
  useSearchArrayParam,
  useSearchParam,
} from "@/common/hooks/useSearchParam";
import api from "@/common/utils/api";
import dayjs from "@/common/utils/dayjs";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { EnvironmentDisplayName } from "@/environment/components/EnvironmentDisplayName";
import { IntegrationKey } from "@/environment/components/IntegrationKey";
import { useEnvironments } from "@/environment/hooks/useEnvironments";
import { FeatureDisplay } from "@/feature/components/FeatureDisplay";

const MAX_EVENTS_COUNT = 50;
const DAYS_HISTORY = 3;

const satisfactionScoreToText = (score: number) => {
  if (score === 1) return "very unsatisfied";
  if (score === 2) return "unsatisfied";
  if (score === 3) return "neutral";
  if (score === 4) return "satisfied";
  if (score === 5) return "very satisfied";
  return "-";
};

export const cellVerticalAdjust: BoxProps = {
  display: "inline-flex",
  alignItems: "center",
  minH: 7,
};

function ellipsis(input: string, maxLength: number) {
  return input.length > maxLength
    ? input.substring(0, maxLength) + "..."
    : input;
}

function ShortContentCell({ event }: { event: DebugEvent }) {
  const mapping = {
    check: {
      primary: event.featureFlagEvent?.featureKey || "-",
      secondary: event.featureFlagEvent?.evalResult ? "enabled" : "disabled",
    },
    evaluate: {
      primary: event.featureFlagEvent?.featureKey || "-",
      secondary: event.featureFlagEvent?.evalResult ? "enabled" : "disabled",
    },
    track: {
      primary: event?.name || "-",
      secondary:
        (event.attributes ? Object.keys(event?.attributes).length : 0) +
        " attributes",
    },
    user: {
      primary: event.user?.id || "-",
      secondary:
        (event.attributes ? Object.keys(event?.attributes).length : 0) +
        " attributes",
    },
    company: {
      primary: event.company?.id || "-",
      secondary:
        (event.attributes ? Object.keys(event?.attributes).length : 0) +
        " attributes",
    },
    feedback: {
      primary: `"${ellipsis(event.feedback?.comment || "-", 80)}"`,
      secondary: satisfactionScoreToText(event.feedback?.score || 0),
    },
  };

  const { primary, secondary } = mapping[event.debugEventType];
  return (
    <Flex gap={2}>
      <Text fontSize="md" isTruncated>
        {primary}
      </Text>
      <Text color="dimmed" fontSize="sm" isTruncated>
        {secondary}
      </Text>
    </Flex>
  );
}

function SidebarInstructions() {
  const { currentEnv } = useAuthContext();
  const envsQuery = useEnvironments();

  return (
    <SidebarContainer>
      <SidebarSection>
        <SectionHeading>Getting started guide</SectionHeading>
        <SectionDescription>
          Use Segment (several languages) or the Bucket SDK (JS) to send data to
          Bucket.
        </SectionDescription>
        <Box>
          <DocumentationButton
            onClick={() =>
              segmentAnalytics.track("Tracking Getting Started Link Clicked")
            }
          />
        </Box>
      </SidebarSection>
      <SidebarSection>
        <SectionHeading>Integration keys</SectionHeading>
        <SectionDescription>
          Provide these keys to Segment or the Bucket SDK to send data to the
          corresponding app environment.{" "}
          <Link
            href="https://docs.bucket.co/introduction/concepts/app/environment"
            target="_blank"
          >
            Learn more
          </Link>
        </SectionDescription>
        {envsQuery.isLoading ? (
          <AnimatedSpinner size="sm" show />
        ) : !envsQuery.isSuccess ? (
          <Alert status="warning">
            <AlertIcon />
            Unable to load environment keys
          </Alert>
        ) : (
          <Flex direction="column" gap={4} shrink={1}>
            {[envsQuery.data.production, ...envsQuery.data.nonProduction].map(
              (env) => (
                <VStack key={env.id} align="flex-start">
                  <EnvironmentDisplayName environment={env} />
                  <IntegrationKey value={env.publishableKey} w="100%" />
                  <IntegrationKey value={env.secretKey} w="100%" />
                </VStack>
              ),
            )}
            <Link
              as={RouterLink}
              to={GlobalSettingsUrl(currentEnv!, "app-environments")}
            >
              Manage environments
            </Link>
          </Flex>
        )}
      </SidebarSection>
    </SidebarContainer>
  );
}

function FeatureFlagEventSidebarSection({ event }: { event: DebugEvent }) {
  const codeBg = useColorModeValue("gray.50", "gray.750");
  return (
    <>
      <SidebarTable layout="fixed">
        <colgroup>
          <col width="90"></col>
          <col></col>
        </colgroup>
        <Tbody>
          <Tr>
            <Td color="dimmed" fontWeight="medium">
              Key
            </Td>
            <Td textAlign="right">
              <Code>{event.featureFlagEvent?.featureKey}</Code>
            </Td>
          </Tr>
          <Tr>
            <Td color="dimmed" fontWeight="medium">
              Result
            </Td>
            <Td textAlign="right">
              <Code>
                {event.featureFlagEvent?.evalResult ? "enabled" : "disabled"}
              </Code>
            </Td>
          </Tr>
          {event.featureFlagEvent &&
            event.featureFlagEvent?.flagVersion > 0 && (
              <Tr>
                <Td color="dimmed" fontWeight="medium">
                  Flag version
                </Td>
                <Td textAlign="right">
                  <Code>{event.featureFlagEvent?.flagVersion}</Code>
                </Td>
              </Tr>
            )}
        </Tbody>
      </SidebarTable>
      <JsonFieldSidebarSection
        content={event.featureFlagEvent?.evalContext}
        label="Evaluation context"
      />
      <Flex direction="column" gap={2}>
        <HStack spacing={1}>
          <Text color="dimmed" fontSize="sm" fontWeight="medium">
            Evaluation missing fields
          </Text>
          {event.featureFlagEvent?.evalMissingFields &&
            event.featureFlagEvent?.evalMissingFields.length > 0 && (
              <Badge colorScheme="orange" size="md" variant="subtle">
                <HStack spacing={1}>
                  <RiErrorWarningLine size={16} />
                  <Text fontSize="sm">Missing fields</Text>
                </HStack>
              </Badge>
            )}
        </HStack>
        <Code
          bg={codeBg}
          borderRadius="base"
          overflowX="scroll"
          px={3}
          py={1.5}
          whiteSpace="pre"
        >
          {JSON.stringify(event.featureFlagEvent?.evalMissingFields, null, 2)}
        </Code>
      </Flex>
    </>
  );
}

function SharedFieldsSidebarSection({ event }: { event: DebugEvent }) {
  return (
    <SidebarTable layout="fixed">
      <colgroup>
        <col width="90"></col>
        <col></col>
      </colgroup>
      <Tbody>
        <Tr>
          <Td color="dimmed" fontWeight="medium">
            Type
          </Td>
          <Td alignContent="right" alignItems="right" alignSelf="right">
            <Flex align="right" justify="flex-end">
              <DebugEventTypeDisplay type={event.debugEventType} />
            </Flex>
          </Td>
        </Tr>
        <Tr>
          <Td color="dimmed" fontWeight="medium">
            User
          </Td>
          <Td textAlign="right">
            <Flex justify="flex-end">
              {event.user ? (
                <UserDisplay
                  fontSize="sm"
                  user={{
                    id: event.user?.id,
                    name: event.user?.name || null,
                    email: event.user?.email || "",
                  }}
                />
              ) : (
                <NotAvailableCell />
              )}
            </Flex>
          </Td>
        </Tr>
        <Tr>
          <Td color="dimmed" fontWeight="medium">
            User ID
          </Td>
          <Td textAlign="right">
            <Code>{event.user?.id}</Code>
          </Td>
        </Tr>
        <Tr>
          <Td color="dimmed" fontWeight="medium">
            Company
          </Td>
          <Td textAlign="right">
            <Flex justify="flex-end">
              {event.company ? (
                <CompanyDisplay
                  company={{
                    id: event.company?.id,
                    name: event.company?.name || null,
                  }}
                  link
                />
              ) : (
                <NotAvailableCell />
              )}
            </Flex>
          </Td>
        </Tr>
        <Tr>
          <Td color="dimmed" fontWeight="medium">
            Company ID
          </Td>
          <Td maxW="0" textAlign="right">
            <Code>{event.company?.id}</Code>
          </Td>
        </Tr>
        <Tr>
          <Td color="dimmed" fontWeight="medium">
            Timestamp
          </Td>
          <Td textAlign="right">
            <TimestampCell value={event.timestamp} autoUpdate />
          </Td>
        </Tr>
        <Tr>
          <Td color="dimmed" fontWeight="medium">
            SDK Version
          </Td>
          <Td textAlign="right">
            <Text fontSize="sm" isTruncated>
              {event.sdkVersion}
            </Text>
          </Td>
        </Tr>
        {event.debugEventType === "track" && (
          <Tr>
            <Td color="dimmed" fontWeight="medium">
              Event Name
            </Td>
            <Td textAlign="right">
              <Text fontSize="sm" isTruncated>
                <Code>{event?.name}</Code>
              </Text>
            </Td>
          </Tr>
        )}
        {event.debugEventType === "feedback" && (
          <Tr>
            <Td color="dimmed" fontWeight="medium">
              Score
            </Td>
            <Td textAlign="right">
              <Text fontSize="sm" isTruncated>
                {satisfactionScoreToText(event.feedback?.score || 0)}
              </Text>
            </Td>
          </Tr>
        )}
      </Tbody>
    </SidebarTable>
  );
}

function JsonFieldSidebarSection({
  label,
  content,
}: {
  label: string;
  content: any;
}) {
  const codeBg = useColorModeValue("gray.50", "gray.750");
  return (
    <Flex direction="column" gap={2}>
      <Text color="dimmed" fontSize="sm" fontWeight="medium">
        {label}
      </Text>
      <Code
        bg={codeBg}
        borderRadius="base"
        overflowX="scroll"
        px={3}
        py={1.5}
        whiteSpace="pre"
      >
        {JSON.stringify(content, null, 2)}
      </Code>
    </Flex>
  );
}

function newFeatureKeyFromEvent(event: DebugEvent): string {
  if (event.debugEventType === "track") {
    return event?.name || "";
  } else if (
    event.debugEventType === "check" ||
    event.debugEventType === "evaluate"
  ) {
    return event.featureFlagEvent?.featureKey || "";
  }

  return "";
}

function titleCase(str: string) {
  const splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}

function newFeatureNameFromKey(key: string): string {
  // replace all following characters with space: -, _, .
  return titleCase(key.replace(/[-_.]/g, " "));
}

export function SidebarEventDetails({
  event,
  onClose,
}: {
  event: DebugEvent;
  onClose: () => void;
}) {
  const { currentEnv } = useAuthContext();
  const codeBg = useColorModeValue("gray.50", "gray.750");

  return (
    <SidebarContainer position="sticky" top={0}>
      <SidebarSection>
        <Flex align="center" direction="row">
          <SectionHeading flexGrow={1}>Event details</SectionHeading>
          <IconButton
            aria-label="Close event details"
            icon={<RiCloseLine size={16} />}
            size="2xs"
            variant="ghost"
            isRound
            onClick={onClose}
          />
        </Flex>
        <SharedFieldsSidebarSection event={event} />
        {(event.debugEventType === "check" ||
          event.debugEventType === "evaluate") && (
          <FeatureFlagEventSidebarSection event={event} />
        )}
        {(event.debugEventType === "user" ||
          event.debugEventType === "company") && (
          <JsonFieldSidebarSection
            content={event?.attributes}
            label="Attributes"
          />
        )}
        {event.debugEventType === "feedback" && (
          <Flex direction="column" gap={2}>
            <Text color="dimmed" fontSize="sm" fontWeight="medium">
              Comment
            </Text>
            <Code bg={codeBg} borderRadius="base" px={3} py={1.5}>
              {event.feedback?.comment || ""}
            </Code>
          </Flex>
        )}
      </SidebarSection>

      {event.debugEventType !== "company" &&
        event.debugEventType !== "user" && (
          <SidebarSection>
            <SectionHeading>Related features</SectionHeading>
            <Box minH={6}>
              {event.features.length === 0 && (
                <Text color="dimmed">No related features</Text>
              )}
              {event.features.length > 0 && (
                <Flex as={List} direction="column" gap={4}>
                  {event.features.map((f) => (
                    <ListItem key={f.id}>
                      <FeatureDisplay feature={f} includeParents={false} link />
                    </ListItem>
                  ))}
                </Flex>
              )}
            </Box>
            {event.features.length === 0 && (
              <Button
                as={RouterLink}
                colorScheme="brand"
                size="sm"
                to={NewFeatureUrl(currentEnv!, {
                  key: newFeatureKeyFromEvent(event),
                  name: newFeatureNameFromKey(newFeatureKeyFromEvent(event)),
                })}
              >
                Track new feature
              </Button>
            )}
          </SidebarSection>
        )}
    </SidebarContainer>
  );
}

function EventNameFilter({
  value = "",
  onChange,
  size = "sm",
}: {
  value?: string;
  onChange: (value: string) => void;
  size?: InputProps["size"];
}) {
  const searchIconColor = useColorModeValue("gray.400", "gray.600");
  return (
    <InputGroup size={size}>
      <InputLeftElement color={!value ? searchIconColor : undefined}>
        <RiSearchLine size={16} />
      </InputLeftElement>
      <Input
        autoComplete="off"
        placeholder="Search by event"
        type="search"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </InputGroup>
  );
}

export default function DebuggerRevamped() {
  const { envId, appId } = useCurrentEnv();
  const [latestTS, setLatestTS] = useState<number>(
    dayjs().subtract(DAYS_HISTORY, "days").valueOf(),
  );
  const [cachedEvents, setCachedEvents] = useState<DebugEvent[]>([]);
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(true);
  const refetchInterval = () => (shouldRefetch ? 1000 : false);
  const { currentEnv } = useAuthContext();

  const [searchQuery, setSearchQuery] = useSearchParam("search");
  const [companyId, setCompanyId] = useSearchParam("company");
  const [userId, setUserId] = useSearchParam("user");
  const [featureId, setFeatureId] = useSearchParam("feature");
  const [eventTypes, setEventTypes] = useSearchArrayParam("eventTypes");

  const validEventTypes = useMemo(() => {
    return eventTypes.filter((type) =>
      (ValidDebugEventTypes as unknown as string[]).includes(type),
    ) as DebugEvent["debugEventType"][];
  }, [eventTypes]);

  const params = useMemo(() => {
    setCachedEvents([]);
    setLatestTS(dayjs().subtract(DAYS_HISTORY, "days").valueOf());

    return {
      envId,
      searchQuery,
      companyId,
      userId,
      featureId,
      validEventTypes,
    };
  }, [searchQuery, companyId, userId, featureId, envId, validEventTypes]);

  const { isLoading } = useQuery({
    queryKey: [
      "environment",
      params.envId,
      "debugEvents",
      params.searchQuery,
      params.companyId,
      params.userId,
      params.featureId,
      params.validEventTypes,
    ],

    queryFn: () =>
      api
        .get<"/apps/:appId/debug-events/latest">(
          `/apps/${appId}/debug-events/latest`,
          {
            params: {
              envId: params.envId!,
              lastTS: String(latestTS),
              eventName: params.searchQuery,
              featureId: params.featureId,
              companyId: params.companyId,
              userId: params.userId,
              eventTypes: params.validEventTypes,
            },
          },
        )
        .then((res) => {
          console.log("request happened", { params });
          return res;
        })
        .then((res) => res.data.events)
        .then((events) => {
          if (events && events.length > 0) {
            // save new events
            setCachedEvents((cachedEvents) =>
              uniqWith([...events, ...cachedEvents], isEqual).slice(
                0,
                MAX_EVENTS_COUNT,
              ),
            );

            // Update timestamp
            const latestIncomingTS = Date.parse(events[0].timestamp);
            setLatestTS(latestIncomingTS);
          }

          return events;
        }),

    gcTime: 0,
    enabled: !!appId && !!params.envId,
    refetchInterval: refetchInterval,
  });

  const [activeEvent, setActiveEvent] = useState<DebugEvent | null>(null);

  const columnHelper = createColumnHelper<DebugEvent>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("timestamp", {
        header: "Received",
        cell: (cell) => {
          const value = cell.getValue();
          return (
            <Box {...cellVerticalAdjust}>
              {value !== undefined ? (
                <TimestampCell value={value} autoUpdate leftAlign />
              ) : (
                <NotAvailableCell />
              )}
            </Box>
          );
        },
      }),
      columnHelper.accessor("debugEventType", {
        header: "Type",
        cell: (cell) => {
          const value = cell.getValue();
          return (
            <Box {...cellVerticalAdjust}>
              {value !== undefined ? (
                <DebugEventTypeDisplay type={value} />
              ) : (
                <NotAvailableCell />
              )}
            </Box>
          );
        },
      }),
      columnHelper.accessor("id", {
        header: "Event",
        cell: (cell) => {
          return (
            <Box {...cellVerticalAdjust}>
              <ShortContentCell event={cell.row.original} />
            </Box>
          );
        },
      }),
      columnHelper.accessor("features", {
        header: "Feature",
        cell: (cell) => {
          return (
            <Box {...cellVerticalAdjust}>
              {cell.getValue().length === 0 && <NotAvailableCell />}
              {cell.getValue().length === 1 && (
                <FeatureDisplay
                  feature={cell.getValue()[0]}
                  includeParents={false}
                  link
                />
              )}
              {cell.getValue().length > 1 && (
                <Text color="dimmed" fontSize="sm">
                  {cell.getValue().length} features
                </Text>
              )}
            </Box>
          );
        },
      }),
      columnHelper.accessor("company.id", {
        header: "Company",
        cell: (cell) => {
          return (
            <Box {...cellVerticalAdjust}>
              {cell.row.original.company ? (
                <CompanyDisplay
                  company={{
                    id: cell.row.original.company.id,
                    name: cell.row.original.company.name || null,
                  }}
                  link
                />
              ) : (
                <NotAvailableCell />
              )}
            </Box>
          );
        },
      }),
      columnHelper.accessor("user.id", {
        header: "User",
        cell: (cell) => {
          return (
            <Box {...cellVerticalAdjust}>
              {cell.row.original.user ? (
                <UserDisplay
                  user={{
                    id: cell.row.original.user.id,
                    name: cell.row.original.user.name || null,
                    email: cell.row.original.user.email || "",
                  }}
                />
              ) : (
                <NotAvailableCell />
              )}
            </Box>
          );
        },
      }),
    ],
    [columnHelper],
  );

  return (
    <SidebarLayout
      flexGrow={1}
      sidebarContent={
        activeEvent !== null ? (
          <SidebarEventDetails
            event={activeEvent}
            onClose={() => setActiveEvent(null)}
          />
        ) : (
          <SidebarInstructions />
        )
      }
      templateColumns="repeat(3, 1fr) clamp(230px, 20%, 400px)"
    >
      <DataTable
        _toolbarLeft={{ wrap: "wrap", alignSelf: "flex-start" }}
        _toolbarRight={{ wrap: "wrap", flex: "1 1 min-content" }}
        columns={columns}
        data={cachedEvents}
        emptyState={
          <EmptyState
            description={
              <>
                Events will show up here as soon as they come in. Note: tracking
                data is{" "}
                <Link
                  as={RouterLink}
                  to={GlobalSettingsUrl(currentEnv!, "app-environments")}
                >
                  environment
                </Link>{" "}
                specific!
              </>
            }
            h={"100%"}
            icon={<PointerPressIcon height="48px" width="48px" />}
            isLoading={isLoading}
            title={`No events past ${DAYS_HISTORY} days`}
          />
        }
        highlightRow={(row) => row.original.id === activeEvent?.id}
        isFetching={isLoading}
        sorting={[]}
        tableId="debug-events"
        toolbarLeftActions={
          <HStack gap={1}>
            <EventNameFilter value={searchQuery} onChange={setSearchQuery} />
            <DebugEventTypeSelect
              placeholder="Any event"
              value={validEventTypes}
              onChange={setEventTypes}
            />
            <CompanyAutocompleteSelect
              placeholder="Any company"
              value={companyId}
              canClear
              valueAsId
              onChange={setCompanyId}
            />
            <UserAutocompleteSelect
              placeholder="Any user"
              value={userId}
              canClear
              valueAsId
              onChange={setUserId}
            />
            <FeatureAutocompleteSelect
              placeholder="Any feature"
              value={featureId}
              canClear
              valueAsId
              onChange={setFeatureId}
            />
          </HStack>
        }
        toolbarRightActions={
          <SwitchButtonGroup
            buttons={[
              {
                id: "live",
                label: "Live",
                leftIcon: shouldRefetch ? (
                  <InfiniteAnimation>
                    <RiFlashlightLine size={15} />
                  </InfiniteAnimation>
                ) : (
                  <RiFlashlightLine size={15} />
                ),
              },
              {
                id: "paused",
                label: "Paused",
              },
            ]}
            value={shouldRefetch ? "live" : "paused"}
            onChange={(value) => setShouldRefetch(value === "live")}
          />
        }
        variant="clickable"
        onRowClick={(row) => setActiveEvent(row.original)}
      />
    </SidebarLayout>
  );
}
