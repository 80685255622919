import uniq from "lodash/uniq";
import { z } from "zod";

import { PaginationQueryBaseSchema } from "./schemas/dataTableSchema";
import { BucketUser } from "./types/BucketUser";
import { APIResponse } from "./api";
import { EnvironmentSelectionQuerySchema } from "./environmentAPI";

export const FeatureConfigVariantSchema = z
  .object({
    key: z.string().nonempty(),
    payload: z.any(),
  })
  .strict();

export type FeatureConfigVariant = z.output<typeof FeatureConfigVariantSchema>;

const FeatureConfigRuleSchema = z
  .object({
    segmentIds: z.array(z.string().nonempty()),
    companyIds: z.array(z.string().nonempty()),
    userIds: z.array(z.string().nonempty()),
    default: z.boolean(),
  })
  .strict();

export type FeatureConfigRule = z.output<typeof FeatureConfigRuleSchema>;

export type FeatureConfigVariantWithRule = {
  variant: FeatureConfigVariant;
  rule: FeatureConfigRule;
};

export type FeatureConfigVersion = {
  id: string;

  variants: FeatureConfigVariantWithRule[];

  version: number;
  currentVersion: boolean;
  changeDescription: string | null;

  createdAt: string;
  createdBy: BucketUser | null;
};

export type FeatureConfig = {
  [environmentId: string]: FeatureConfigVersion;
};

const FeatureConfigVariantWithEnvironmentRulesSchema = z.object({
  variant: FeatureConfigVariantSchema,
  environmentRules: z.record(z.string().nonempty(), FeatureConfigRuleSchema),
});

export type FeatureConfigVariantWithEnvironmentRules = z.output<
  typeof FeatureConfigVariantWithEnvironmentRulesSchema
>;

export const UpdateFeatureConfigSchema = z.object({
  changeDescription: z.string().max(512).nullish(),
  variants: FeatureConfigVariantWithEnvironmentRulesSchema.array()
    .refine(
      (variants) => {
        if (!variants.length) {
          return true;
        }

        const environmentIds = uniq(
          variants.flatMap((v) => Object.keys(v.environmentRules)),
        );

        return environmentIds.every((envId) => {
          const rulesForEnv = variants
            .map((v) => v.environmentRules[envId])
            .filter(Boolean);

          return (
            rulesForEnv.length === 0 ||
            rulesForEnv.filter((r) => r.default).length === 1
          );
        });
      },
      {
        message:
          "One, and only one, rule must be set as default per environment",
      },
    )
    .refine(
      (variants) => {
        const keys = variants.map((v) => v.variant.key);
        return new Set(keys).size === keys.length;
      },
      { message: "Variant keys must be unique" },
    ),
});

export type UpdateFeatureConfig = z.infer<typeof UpdateFeatureConfigSchema>;

export const GetFeatureConfigVersionsQuerySchema = PaginationQueryBaseSchema({
  pageSize: 50,
  sortOrder: "desc",
})
  .merge(
    z.object({
      sortBy: z.enum(["version"]).default("version"),
    }),
  )
  .merge(EnvironmentSelectionQuerySchema)
  .strict();

export type GetFeatureConfigVersionsQuery = z.input<
  typeof GetFeatureConfigVersionsQuerySchema
>;

export const UpdateFeatureConfigQuerySchema = z
  .object({
    expectedVersionIds: z.string().array().nonempty().optional(),
  })
  .strict();

export type UpdateFeatureConfigQuery = z.input<
  typeof UpdateFeatureConfigQuerySchema
>;

export interface FeatureConfigAPI {
  "/apps/:appId/features/:featureId/config": {
    GET: {
      response: APIResponse<FeatureConfig>;
      params: { appId: string; featureId: string };
    };
    PUT: {
      body: UpdateFeatureConfig;
      response: APIResponse<FeatureConfig>;
      params: { appId: string; featureId: string };
      query: UpdateFeatureConfigQuery;
    };
  };
  "/apps/:appId/features/:featureId/config/versions": {
    GET: {
      response: APIResponse<{ versions: FeatureConfigVersion[] }>;
      params: { appId: string; featureId: string };
      query: GetFeatureConfigVersionsQuery;
    };
  };
}
