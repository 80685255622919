import { Outlet, useParams } from "react-router-dom";
import { Flex } from "@chakra-ui/react";

import { SetupInstructions } from "@/feature/components/SetupInstructions";
import { useFeatureData } from "@/feature/data/useFeatureData";
import { FlagTargeting } from "@/flags/components/FlagTargeting";
import FeatureSideBarWrapper from "../components/FeatureSidebarWrapper";

export default function FeatureTargeting() {
  const { featureId } = useParams();
  const { data: feature } = useFeatureData(featureId!);

  return (
    <FeatureSideBarWrapper>
      <Flex direction="column" flexGrow={1} h="full" px={6} py={4}>
        <SetupInstructions featureId={featureId} usecase="access" />
        {feature?.flagId && <FlagTargeting flagId={feature.flagId} />}
        <Outlet />
      </Flex>
    </FeatureSideBarWrapper>
  );
}
