import { Center, Spinner } from "@chakra-ui/react";
import { useLocalStorage } from "usehooks-ts";

import {
  BucketProvider,
  useFeature as useBucketFeature,
} from "@bucketco/react-sdk";
import {
  AvailableFeatures,
  availableFeatures,
} from "@bucketco/shared/features";

import { useAuthContext } from "@/auth/contexts/authContext";
import { getAllowTracking } from "@/common/utils/analytics/blockNonMembertracking";
import { API_URL, BUCKET_PUBLISHABLE_KEY, FEATURES } from "@/common/utils/env";

declare module "@bucketco/react-sdk" {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  interface Features extends AvailableFeatures {}
}

export function FeatureFlagsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { user, currentOrg } = useAuthContext();

  const userCtx = {
    id: user?.id,
    email: user?.email,
    name: user?.name,
  };

  const companyCtx = {
    id: currentOrg?.id,
    access_level: currentOrg?.accessLevel,
    created_at: currentOrg?.createdAt,
  };

  // skip rendering the Bucket component if the tracking key is not set
  // or we're not logged in/bootstrapping
  if (!BUCKET_PUBLISHABLE_KEY || !user?.id) {
    return children;
  }

  return (
    <BucketProvider
      apiBaseUrl={`${API_URL}/ingest-proxy`}
      company={companyCtx}
      enableTracking={getAllowTracking()}
      featureOptions={{
        staleWhileRevalidate: false,
      }}
      loadingComponent={
        <Center h="100vh">
          <Spinner size="sm" />
        </Center>
      }
      publishableKey={BUCKET_PUBLISHABLE_KEY}
      user={userCtx}
    >
      {children}
    </BucketProvider>
  );
}

function useLocalOverrideStorage() {
  return useLocalStorage<Record<string, boolean>>(
    "bucket-targeting-overrides",
    {},
  );
}

export function useFeatures() {
  const [localFeatures, setLocalFeatures] = useLocalOverrideStorage();

  const resetLocalOverrides = () => {
    setLocalFeatures({});
  };

  const hasLocalOverrides = Object.keys(localFeatures).length > 0;

  return {
    availableFeatures,
    hasLocalOverrides,
    resetLocalOverrides,
  };
}

export function useFeature(key: keyof AvailableFeatures) {
  const [localFeatures, setLocalFeatures] = useLocalOverrideStorage();

  const updateLocalOverride = (value: boolean | null) => {
    setLocalFeatures((features) => {
      if (value === null) {
        delete features[key];
      } else {
        features[key] = value;
      }
      return features;
    });
  };

  const {
    isEnabled: evaluation,
    isLoading,
    track,
    requestFeedback,
  } = useBucketFeature(key);

  const envVar = FEATURES[key] ?? null;
  const override = localFeatures[key] ?? null;

  return {
    isLoading,
    isEnabled: override ?? envVar ?? evaluation,
    values: {
      evaluation,
      envVar,
      override,
    },
    updateLocalOverride,
    track,
    requestFeedback,
  };
}
