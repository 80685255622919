import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  Box,
  ButtonGroup,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { z } from "zod";

import {
  SendPromptArgsSchema,
  SendPromptDTO,
} from "@bucketco/shared/featureFeedbackAPI";

import { UserAutocompleteSelect } from "@/common/components/Autocomplete/UserAutocompleteSelect";
import { FormRootError } from "@/common/components/Form/FormRootError";
import FormSubmitLegacy from "@/common/components/Form/FormSubmitLegacy";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import ModalCancelButton from "@/common/components/ModalCancelButton";
import useApiForm from "@/common/hooks/useApiForm";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { UserOnlineStatus } from "@/feature/components/UserOnlineStatus";
import { useUserFeedbackPromptingStatus } from "@/feature/data/useUserFeedbackPromptingStatus";

type PromptFormType = z.input<typeof SendPromptArgsSchema>;

type FeedbackPromptModalProps = {
  isOpen: boolean;
  onClose: () => void;
  question?: string;
};

export function FeedbackPromptModal({
  isOpen,
  onClose,
  question,
}: FeedbackPromptModalProps) {
  const { featureId } = useParams();
  const { envId, appId } = useCurrentEnv();
  const toast = useToast();

  const { form, handleSubmit } = useApiForm<PromptFormType, SendPromptDTO>(
    async (data) => {
      const res =
        await api.post<"/apps/:appId/features/:featureId/feedback/prompt">(
          `/apps/${appId}/features/${featureId}/feedback/prompt`,
          data,
          { params: { envId: envId! } },
        );
      return res.data;
    },
    SendPromptArgsSchema,
    {
      onSuccess: () => {
        segmentAnalytics.track("Test Prompt Sent", {
          section: "FeedbackPrompting",
        });
        toast({
          title: "Test prompt sent",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      },
    },
    {
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues: { question },
    },
  );

  useEffect(() => {
    form.setValue("question", question);
  }, [form, question]);

  const userId = form.watch("userId");
  const userStatus = useUserFeedbackPromptingStatus(userId);

  return (
    <Modal isOpen={isOpen} size="md" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="lg">Try automated survey in-app</Heading>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <FormProvider {...form}>
              <VStack align="flex-start" maxW="compactForm" spacing={4}>
                <Box color="gray.500">
                  Here you can test the automated survey integration directly in
                  your app by triggering a prompt for a given user.
                </Box>

                <ManagedFormControl
                  label="User"
                  name="userId"
                  render={({ field }) => (
                    <UserAutocompleteSelect
                      {...field}
                      placeholder="Select a user"
                      valueAsId
                    />
                  )}
                />

                <UserOnlineStatus userStatus={userStatus.data!} />

                <FormRootError />
                <FormSubmitLegacy>Trigger prompt</FormSubmitLegacy>
              </VStack>
            </FormProvider>
          </form>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <ModalCancelButton shouldNavigate={false} onClick={onClose} />
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
