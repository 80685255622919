import { Link } from "react-router-dom";
import { Avatar, HStack, StackProps, Text } from "@chakra-ui/react";

import { CompanyUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";

type Props = StackProps & {
  company: {
    id: string;
    name: string | null;
    avatar?: string | null;
  };
  link?: boolean;
};

export default function CompanyDisplay({ company, link, ...rest }: Props) {
  const { currentEnv } = useAuthContext();

  if (link) {
    return (
      <Text as={Link} to={CompanyUrl(currentEnv!, company.id)}>
        <CompanyDisplayInner company={company} {...rest} />
      </Text>
    );
  }

  return <CompanyDisplayInner company={company} {...rest} />;
}

function CompanyDisplayInner({ company, ...rest }: Props) {
  return (
    <HStack {...rest}>
      <Avatar
        name={company.name || company.id}
        size="2xs"
        src={company.avatar ?? undefined}
      />
      <Text fontSize={rest.fontSize} noOfLines={1}>
        {company.name || company.id}
      </Text>
    </HStack>
  );
}
