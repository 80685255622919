import { forwardRef, useMemo } from "react";
import { RiArrowDownSLine, RiCloseLine } from "react-icons/ri";
import {
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  MenuProps,
  Text,
  useFormControl,
} from "@chakra-ui/react";

import { MenuArrow } from "@/common/components/MenuArrow";
import MenuDescription from "@/common/components/MenuDescription";
import { EnvironmentDisplayName } from "@/environment/components/EnvironmentDisplayName";
import { useEnvironmentsData } from "@/environment/data/useEnvironmentsData";

export type EnvironmentPickerProps = Omit<ButtonProps, "value" | "onChange"> & {
  placement?: MenuProps["placement"];
  size?: ButtonProps["size"];
  placeholder?: string;
  menuDescription?: string | React.ReactNode;
  showClearButton?: boolean;
  showArrow?: boolean;
  value?: string | null;
  onChange?: (value: string | null) => void;
  onClear?: () => void;
};

export const EnvironmentPicker = forwardRef(
  (
    {
      id,
      value,
      size = "sm",
      placeholder = "No environment selected",
      menuDescription = "Select environment:",
      showClearButton = false,
      showArrow = false,
      onChange,
      onClear,
      ...rest
    }: EnvironmentPickerProps,
    ref,
  ) => {
    const formControlProps = useFormControl<HTMLButtonElement>(rest);

    const { data: environments = [], isLoading: isLoadingEnvironments } =
      useEnvironmentsData();

    const selectedEnvironment = useMemo(
      () => environments.find((env) => env.id === value),
      [environments, value],
    );

    const clearButtonCallback = () => {
      onChange?.(null);
      onClear?.();
    };

    return (
      <Menu autoSelect={false} id={id} closeOnSelect>
        <ButtonGroup isAttached>
          <MenuButton
            {...formControlProps}
            ref={ref}
            as={Button}
            color={!value ? "dimmed" : undefined}
            data-testid="environment-picker"
            isDisabled={formControlProps.disabled}
            isLoading={isLoadingEnvironments}
            loadingText="Loading..."
            rightIcon={
              <Box fontSize="xl" mr={-2}>
                <RiArrowDownSLine />
              </Box>
            }
            size={size}
            variant="input"
            {...rest}
          >
            {selectedEnvironment ? (
              <EnvironmentDisplayName environment={selectedEnvironment} />
            ) : (
              <Text color="dimmed">{placeholder}</Text>
            )}
          </MenuButton>
          {value && showClearButton && (
            <IconButton
              aria-label="Clear selection"
              icon={<RiCloseLine />}
              isDisabled={rest.isDisabled}
              marginInlineStart={"-1px"}
              size={size}
              variant="outline"
              onClick={clearButtonCallback}
            />
          )}
        </ButtonGroup>
        <MenuList
          data-testid="environment-picker-list"
          maxH="sm"
          maxW={280}
          overflow="auto"
          rootProps={{ style: { transform: "scale(0)" } }}
        >
          {showArrow && <MenuArrow />}
          <MenuDescription>{menuDescription}</MenuDescription>
          <MenuDivider my={0} />
          <MenuOptionGroup
            type="radio"
            value={value ?? ""}
            onChange={onChange as any}
          >
            {environments.map((environment) => (
              <MenuItemOption key={environment.id} value={environment.id}>
                <EnvironmentDisplayName environment={environment} />
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    );
  },
);
EnvironmentPicker.displayName = "EnvironmentPicker";
