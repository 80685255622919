import { useQuery } from "@tanstack/react-query";

import { useAuthContext } from "@/auth/contexts/authContext";
import api from "@/common/utils/api";

import { featureQueryKeys } from "./featureQueryKeys";

export function useFeatureConfigData(featureId?: string) {
  const { currentApp } = useAuthContext();

  return useQuery({
    queryKey: featureQueryKeys.config(currentApp?.id, featureId),
    queryFn: () =>
      api
        .get<"/apps/:appId/features/:featureId/config">(
          `/apps/${currentApp?.id}/features/${featureId}/config`,
        )
        .then((res) => res.data),
    enabled: !!currentApp && !!featureId,
  });
}
