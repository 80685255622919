import { Divider, Flex } from "@chakra-ui/react";

import { useAuthContext } from "@/auth/contexts/authContext";
import { ConnectWithSlack } from "@/common/components/ConnectWithSlack";
import { useSlackConnected } from "@/common/hooks/useSlackConnected";
import { OnlyProductionEnvironment } from "@/environment/components/EnvironmentCallouts";

type Props = {
  productionOnly?: boolean;
  children?: React.ReactNode;
};

export default function SlackConnectionGuard({
  productionOnly = false,
  children,
}: Props) {
  const { currentEnv } = useAuthContext();
  const isSlackConnected = useSlackConnected();

  if (productionOnly && !currentEnv?.isProduction) {
    <OnlyProductionEnvironment entity="slack reports" />;
  }

  return (
    <Flex direction="column" gap={4}>
      {!isSlackConnected && (
        <>
          <ConnectWithSlack />
          <Divider />
        </>
      )}
      {children}
    </Flex>
  );
}
