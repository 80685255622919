import { useEffect, useMemo, useRef, useState } from "react";
import { RiDraggable } from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";
import {
  Alert,
  AlertDescription,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Link,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDragControls } from "framer-motion";

import { StageDTO } from "@bucketco/shared/stageAPI";
import { GlobalSettingsStageUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import AnimatedSpinner from "@/common/components/AnimatedSpinner";
import {
  DeleteIconButton,
  EditIconButton,
} from "@/common/components/CommonIconButtons";
import { ReorderList } from "@/common/components/ReorderList";
import SimpleSelect from "@/common/components/SimpleSelect";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import { useSearchParam } from "@/common/hooks/useSearchParam";
import api from "@/common/utils/api";
import pluralize from "@/common/utils/pluralize";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import {
  ListItem,
  ListLayout,
  ListLayoutProps,
} from "@/global-settings/components/GlobalSettingsHelpers";
import { Stage } from "@/global-settings/components/Stage";
import { StageDisplayName } from "@/stage/components/StageDisplayName";
import { stageQueryKeys } from "@/stage/data/stageQueryKeys";
import { useStagesData } from "@/stage/data/useStagesData";
import { useStageUpdateMutation } from "@/stage/data/useStagesMutations";

const Layout = (props: ListLayoutProps) => (
  <ListLayout templateColumns="2fr 1fr 56px" {...props} />
);

type ItemProps = {
  stage: StageDTO;
  stages: StageDTO[];
  canDrag?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
};

function Item({ stage, stages, canDrag, canEdit, canDelete }: ItemProps) {
  const { currentEnv } = useAuthContext();

  const dragControls = useDragControls();

  return (
    <ListItem
      canDrag={canDrag}
      dragControls={dragControls}
      gridTemplateColumns="2fr 1fr 56px"
      value={stage}
    >
      {canDrag && (
        <Box
          boxSize={3}
          left={-5}
          position="absolute"
          sx={{
            cursor: "grab",
            "&:active": {
              cursor: "grabbing",
            },
          }}
          onPointerDown={(e) => {
            if (!canDrag) return;
            dragControls.start(e);
          }}
        >
          <RiDraggable />
        </Box>
      )}
      <StageDisplayName stage={stage} isTruncated />
      <Text fontSize="sm">{stage.featureCount}</Text>
      <Flex justify="flex-end">
        {canEdit && (
          <EditIconButton
            label="Edit stage"
            to={GlobalSettingsStageUrl(currentEnv!, stage.id)}
          />
        )}
        {canDelete && <DeleteStage stage={stage} stages={stages} />}
      </Flex>
    </ListItem>
  );
}

function DeleteStage({
  stage,
  stages,
}: {
  stage: StageDTO;
  stages: StageDTO[];
}) {
  const { currentApp } = useAuthContext();
  const stageOptions = useMemo(
    () =>
      stages
        .filter((s) => s.id !== stage.id)
        .map((s) => ({
          value: s.id,
          label: <StageDisplayName stage={s} />,
        })),
    [stage, stages],
  );
  const [selectedStage, setSelectedStage] = useState(stageOptions[0]?.value);
  useEffect(() => {
    // Reset selected stage when stage options change
    setSelectedStage(stageOptions[0]?.value);
  }, [stageOptions]);

  const toast = useToast();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  const { mutate: deleteStage, isPending: isDeleteLoading } = useMutation({
    mutationFn: () =>
      api.delete<"/apps/:appId/stages/:stageId">(
        `/apps/${currentApp?.id}/stages/${stage.id}`,
        {
          data: {
            newStageId: selectedStage,
          },
        },
      ),

    retry: 0,

    onSuccess: async () => {
      segmentAnalytics.track("Stage Deleted");

      await queryClient.invalidateQueries({
        queryKey: stageQueryKeys.list(currentApp?.id),
      });

      toast({
        title: `Deleted stage "${stage.name}"`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    },

    onError: () => {
      errorToast({
        title: `Failed to delete '${stage.name}' stage`,
      });
    },
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  return (
    <>
      <DeleteIconButton
        isDisabled={isDeleteLoading}
        label="Delete stage"
        onClick={onOpen}
      />
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Delete stage</AlertDialogHeader>
            <AlertDialogBody display="flex" flexDirection="column" gap={2}>
              <Text>
                Are you sure you want to delete stage{" "}
                <strong>{stage.name}</strong>?
                <br />
                Your features&apos; targeting rules will <strong>
                  NOT
                </strong>{" "}
                change.
                <br />
                This cannot be undone.
              </Text>
              {!!stage.featureCount && (
                <FormControl>
                  <FormLabel>
                    Move {stage.featureCount}{" "}
                    {pluralize("feature", stage.featureCount)} to stage:
                  </FormLabel>
                  <SimpleSelect
                    options={stageOptions}
                    value={selectedStage}
                    onChange={setSelectedStage}
                  />
                </FormControl>
              )}
            </AlertDialogBody>
            <AlertDialogFooter>
              <ButtonGroup>
                <Button
                  ref={cancelRef}
                  isDisabled={isDeleteLoading}
                  variant="ghostDimmed"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  isLoading={isDeleteLoading}
                  onClick={() => deleteStage()}
                >
                  Delete
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

function StagesList({ stages: stagesProp }: { stages: StageDTO[] }) {
  const toast = useToast();
  const [stages, setStages] = useState(stagesProp);
  const updateMutation = useStageUpdateMutation();

  useEffect(() => {
    setStages(stagesProp);
  }, [stagesProp]);

  if (!stages.length) {
    return (
      <Alert status="info">
        <AlertIcon />
        <AlertDescription>This application has no stages</AlertDescription>
      </Alert>
    );
  }

  return (
    <>
      <Layout minH="auto" py={1} w="full">
        <Text color="dimmed" fontSize="sm" fontWeight="medium">
          Name
        </Text>
        <Text color="dimmed" fontSize="sm" fontWeight="medium">
          Features
        </Text>
      </Layout>
      <ReorderList
        axis="y"
        display="flex"
        flexDir="column"
        gap={2}
        values={stages}
        w="full"
        onDrop={(stage, order) => {
          updateMutation.mutateAsync(
            {
              id: stage.id,
              order: order,
            },
            {
              onSuccess: () => {
                segmentAnalytics.track("Stage Reordered");
                toast({
                  title: `Stages reordered`,
                  status: "success",
                  duration: 2000,
                  isClosable: true,
                });
              },
            },
          );
        }}
        onReorder={setStages}
      >
        {stages.map((stage) => (
          <Item
            key={stage.id}
            canDelete={stages.length > 1}
            stage={stage}
            stages={stages}
            canDrag
            canEdit
          />
        ))}
      </ReorderList>
    </>
  );
}

function Page() {
  const { currentEnv } = useAuthContext();
  const stages = useStagesData();

  useEffect(() => {
    segmentAnalytics.page("App Stages Settings");
  }, []);

  return (
    <Flex alignItems="flex-start" direction="column" gap={2} maxW="2xl">
      <Heading as="h3" fontSize="md">
        Release stages
      </Heading>
      <Text color="dimmed" fontSize="sm" maxWidth="2xl" mb={4}>
        Release stages provide environment-specific rule templates that can be
        applied to features. Each stage represents a phase in your release
        pipeline, helping you track feature progression. These templates serve
        as a foundation for feature targeting rules.{" "}
        <Link
          href="https://docs.bucket.co/product-handbook/release-stages"
          target="_blank"
        >
          Learn more
        </Link>
      </Text>
      {stages.isLoading ? (
        <AnimatedSpinner size="sm" show />
      ) : !stages.isSuccess ? (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>Could not load stages</AlertDescription>
        </Alert>
      ) : (
        <>
          <StagesList stages={stages.data} />
          <Button
            as={RouterLink}
            to={GlobalSettingsStageUrl(currentEnv!, "new")}
            variant="outline"
          >
            New stage
          </Button>
        </>
      )}
    </Flex>
  );
}

export default function Stages() {
  const [stageId] = useSearchParam("stage");
  if (stageId) {
    return <Stage stageId={stageId} />;
  } else {
    return <Page />;
  }
}
