import React, { forwardRef, ReactNode } from "react";
import { RiAddLine } from "react-icons/ri";
import {
  Flex,
  HStack,
  SkeletonCircle,
  SkeletonText,
  Tag,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import uniq from "lodash/uniq";

import { CompanyName } from "@bucketco/shared/companyAPI";
import { UserName } from "@bucketco/shared/userAPI";

import {
  companyAutocompleteFilterFn,
  CompanyAutocompleteSelect,
} from "@/common/components/Autocomplete/CompanyAutocompleteSelect";
import {
  userAutocompleteFilterFn,
  UserAutocompleteSelect,
} from "@/common/components/Autocomplete/UserAutocompleteSelect";
import CompanyDisplay from "@/common/components/CompanyDisplay";
import {
  SegmentAllRestrictionsWarning,
  SegmentStatelessRestrictionsWarning,
} from "@/common/components/Rule/RuleRestrictions";
import { SegmentPicker } from "@/common/components/SegmentPicker";
import TagRemove from "@/common/components/TagRemove";
import { UserDisplay } from "@/common/components/UserDisplay";
import { commonQueryKeys } from "@/common/data/commonQueryKeys";
import { useUserNamesData } from "@/common/data/useUserNamesData";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useFeature } from "@/common/hooks/useFeatureFlags";
import { SegmentDisplay } from "@/company/components/SegmentDisplay";
import { companyQueryKeys } from "@/company/data/companyQueryKeys";
import { useCompanyNamesData } from "@/company/data/useCompanyNamesData";
import { useSegments } from "@/company/data/useSegments";

type RuleSimpleSelectionProps = {
  children: ReactNode;
  isLoading?: boolean;
  readOnly?: boolean;
  onRemove?: () => void;
};

export const RuleSimpleSelection = ({
  children,
  isLoading,
  readOnly,
  onRemove,
}: RuleSimpleSelectionProps) => {
  return (
    <Tag pos="relative" role="group" size="sm" variant="subtle">
      {isLoading ? (
        <HStack>
          <SkeletonCircle size="4" />
          <SkeletonText noOfLines={1} w={16} />
        </HStack>
      ) : (
        children
      )}
      {!readOnly && onRemove && <TagRemove onRemove={onRemove} />}
    </Tag>
  );
};

const sharedProps = {
  leftIcon: <RiAddLine size={16} />,
  pl: 1,
  placeholder: "Add",
  rightIcon: undefined,
  variant: "ghost",
};

type SelectorProps = {
  name?: string;
  value?: string[];
  isReadOnly?: boolean;
  onChange?: (value: string[]) => void;
  onBlur?: () => void;
};

export const RuleSimpleSegmentSelector = forwardRef<
  HTMLButtonElement,
  SelectorProps
>(({ name, value, isReadOnly, onChange, onBlur }, ref) => {
  const { data: segments, isLoading } = useSegments();
  const selectedSegments = value?.map(
    (id) => segments?.find((s) => s.id === id) ?? { id, name: id },
  );

  return (
    <Flex align="center" gap={2} wrap="wrap">
      {selectedSegments?.map((segment) => (
        <RuleSimpleSelection
          key={segment.id}
          isLoading={isLoading}
          readOnly={isReadOnly}
          onRemove={() =>
            value && onChange
              ? onChange(value.filter((id) => id !== segment.id))
              : undefined
          }
        >
          <SegmentDisplay segment={segment} />
        </RuleSimpleSelection>
      ))}
      {!isReadOnly && (
        <SegmentPicker
          {...sharedProps}
          ref={ref}
          name={name}
          segmentItemFilter={(s) => !s.isAllSegment && s.stateless}
          segmentItemFilterReason={
            <VStack>
              <SegmentAllRestrictionsWarning />
              <SegmentStatelessRestrictionsWarning />
            </VStack>
          }
          showArrow
          showPieChart
          onBlur={onBlur}
          onChange={(newSelection) => {
            if (!newSelection) return;
            onChange?.(value?.concat(newSelection) ?? [newSelection]);
          }}
        />
      )}
    </Flex>
  );
});
RuleSimpleSegmentSelector.displayName = "RuleSimpleSegmentSelector";

export const RuleSimpleCompanySelector = forwardRef<
  HTMLButtonElement,
  SelectorProps
>(({ name, value, isReadOnly, onChange, onBlur }, ref) => {
  const { appId, envId } = useCurrentEnv();
  const queryClient = useQueryClient();
  const { data: companyNames, isFetching } = useCompanyNamesData(value);

  const { isEnabled: addInBulkEnabled } = useFeature("add-in-bulk");

  return (
    <Flex align="center" gap={2} wrap="wrap">
      {companyNames?.map((company) => (
        <RuleSimpleSelection
          key={company.id}
          isLoading={isFetching && !company.name}
          readOnly={isReadOnly}
          onRemove={() => {
            if (!value || !onChange) return;
            const newValue = value.filter((id) => id !== company.id);
            // Get the current list of companies
            const current = queryClient.getQueryData<CompanyName[]>(
              companyQueryKeys.companyNames(appId, envId, value),
            );
            // Update the list of companies by removing the selected company
            // Note: can't use previous value as queryKey has changed
            queryClient.setQueryData<CompanyName[]>(
              companyQueryKeys.companyNames(appId, envId, newValue),
              () => current?.filter((c) => c.id !== company.id) ?? [],
            );
            onChange(newValue);
          }}
        >
          <CompanyDisplay company={company} />
        </RuleSimpleSelection>
      ))}
      {!isReadOnly && (
        <CompanyAutocompleteSelect
          {...sharedProps}
          ref={ref}
          itemFilterFn={(c, search) => {
            return (
              !value?.includes(c.id) && companyAutocompleteFilterFn(c, search)
            );
          }}
          name={name}
          showArrow
          onAddInBulk={
            addInBulkEnabled
              ? (newCompanyIds: string[]) => {
                  onChange?.(uniq((value || []).concat(newCompanyIds)));
                }
              : undefined
          }
          onBlur={onBlur}
          onChange={(newSelection) => {
            const newValue = value?.concat(newSelection.id) ?? [
              newSelection.id,
            ];
            // Get the current list of companies
            const current = queryClient.getQueryData<CompanyName[]>(
              companyQueryKeys.companyNames(appId, envId, value),
            );
            // Update the list of companies by adding the selected company
            // Note: can't use previous value as queryKey has changed
            queryClient.setQueryData<CompanyName[]>(
              companyQueryKeys.companyNames(appId, envId, newValue),
              () => current?.concat(newSelection) ?? [newSelection],
            );
            onChange?.(newValue);
          }}
        />
      )}
    </Flex>
  );
});
RuleSimpleCompanySelector.displayName = "RuleSimpleCompanySelector";

export const RuleSimpleUserSelector = forwardRef<
  HTMLButtonElement,
  SelectorProps
>(({ name, value, isReadOnly, onChange, onBlur }, ref) => {
  const { appId, envId } = useCurrentEnv();
  const queryClient = useQueryClient();
  const { data: userNames, isFetching } = useUserNamesData(value);

  const { isEnabled: addInBulkEnabled } = useFeature("add-in-bulk");

  return (
    <Flex align="center" gap={2} wrap="wrap">
      {userNames?.map((user) => (
        <RuleSimpleSelection
          key={user.id}
          isLoading={isFetching && !user.name}
          readOnly={isReadOnly}
          onRemove={() => {
            if (!value || !onChange) return;
            const newValue = value.filter((id) => id !== user.id);
            // Get the current list of users
            const current = queryClient.getQueryData<UserName[]>(
              commonQueryKeys.userNames(appId, envId, value),
            );
            // Update the list of users by removing the selected user
            // Note: can't use previous value as queryKey has changed
            queryClient.setQueryData<UserName[]>(
              commonQueryKeys.userNames(appId, envId, newValue),
              () => current?.filter((c) => c.id !== user.id) ?? [],
            );
            onChange(newValue);
          }}
        >
          <UserDisplay fontWeight={500} user={user} />
        </RuleSimpleSelection>
      ))}
      {!isReadOnly && (
        <UserAutocompleteSelect
          {...sharedProps}
          ref={ref}
          itemFilterFn={(u, search) => {
            return (
              !value?.includes(u.id) && userAutocompleteFilterFn(u, search)
            );
          }}
          name={name}
          showArrow
          onAddInBulk={
            addInBulkEnabled
              ? (newUserIds: string[]) => {
                  onChange?.(uniq((value || []).concat(newUserIds)));
                }
              : undefined
          }
          onBlur={onBlur}
          onChange={(newSelection) => {
            const newValue = value?.concat(newSelection.id) ?? [
              newSelection.id,
            ];
            // Get the current list of users
            const current = queryClient.getQueryData<UserName[]>(
              commonQueryKeys.userNames(appId, envId, value),
            );
            // Update the list of users by adding the selected user
            // Note: can't use previous value as queryKey has changed
            queryClient.setQueryData<UserName[]>(
              commonQueryKeys.userNames(appId, envId, newValue),
              () => current?.concat(newSelection) ?? [newSelection],
            );
            onChange?.(newValue);
          }}
        />
      )}
    </Flex>
  );
});
RuleSimpleUserSelector.displayName = "RuleSimpleUserSelector";
